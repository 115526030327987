import './AdminNav.scss'

// Atoms
import Logo from '../../../ruya-shared/shared/ui/atoms/logo/Logo'

// Molecules
import NavigationLink from '../../molecules/navigationLink/NavigationLink'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate } from 'react-router-dom'

// Shared
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

const AdminNav = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Router
	const navigate = useNavigate()

	return (
		<header className="AdminNav">
			<nav className="AdminNav_Nav">
				<ul>
					<li className="AdminNav_Nav_Logo">
						<Logo />
					</li>
					<li>
						<NavigationLink to="/dashboard" label={t('admin:dashboard')} />
					</li>
					<li>
						<NavigationLink to="/page" label={t('admin:pages')} />
					</li>
					<li>
						<NavigationLink to="/article" label={t('admin:articles')} />
					</li>
					<li>
						<NavigationLink to="/users" label={t('admin:users')} />
					</li>
					<li>
						<NavigationLink to="/theories" label={t('admin:theories')} />
					</li>
					<li>
						<NavigationLink to="/plans" label={t('admin:plans')} />
					</li>
				</ul>

				<ul className="AdminNav_Nav_Bottom">
					<li>
						<NavigationLink to={commonSettings.apps.admin.paths.logout} label={t('admin:logout')} />
					</li>
				</ul>
			</nav>
		</header>
	)
}

export default AdminNav
