import { getActiveLanguages, getLanguageCode } from './languageHelper'
import languageSettings from '../config/languageSettings'

// Delete item from array
export function deleteItem<T>(list: T[], val: T): T[] {
	const set = new Set(list)
	set.delete(val)
	return Array.from(set)
}

// Covert first character of string to small capital
export const convertToCamelCase = (str: string) => {
	if (typeof str !== 'string' || str.length === 0) {
		return str // Return the original input if it's not a string or is empty
	}
	return str.charAt(0).toLocaleLowerCase() + str.slice(1)
}

export const capitalizeFirstLetter = (str: string) => {
	if (typeof str !== 'string' || str.length === 0) {
		return str // Return the original input if it's not a string or is empty
	}
	return str.charAt(0).toLocaleUpperCase() + str.slice(1)
}

// Generate random letters
export const generateRandomLetters = (length: number = 10) => {
	const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
	let result = ''
	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * letters.length)
		result += letters[randomIndex]
	}
	return result
}

// Copy to clipboard
export const copyToClipboard = (val: string) => {
	if (val && navigator.clipboard) {
		navigator.clipboard.writeText(val)
	}
}

// Get supported Turnstile captcha languages
export const getSupportedTurnstileLanguage = (isoCode: string | undefined): string => {
	const language = isoCode?.toLowerCase() || languageSettings.defaultLanguage

	if (language) {
		// Supported Turnstile captcha languages
		// https://developers.cloudflare.com/turnstile/reference/supported-languages/
		const captchaLanguages = [
			'ar-eg',
			'ar', // Arabic (Egypt)
			'bg-bg',
			'bg', // Bulgarian (Bulgaria)
			'zh-cn',
			'zh', // Chinese (Simplified, China)
			'zh-tw', // Chinese (Traditional, Taiwan)
			'hr-hr',
			'hr', // Croatian (Croatia)
			'cs-cz',
			'cs', // Czech (Czech Republic)
			'da-dk',
			'da', // Danish (Denmark)
			'nl-nl',
			'nl', // Dutch (Netherlands)
			'en-us',
			'en', // English (United States)
			'fa-ir',
			'fa', // Farsi (Iran)
			'fi-fi',
			'fi', // Finnish (Finland)
			'fr-fr',
			'fr', // French (France)
			'de-de',
			'de', // German (Germany)
			'el-gr',
			'el', // Greek (Greece)
			'he-il',
			'he', // Hebrew (Israel)
			'hi-in',
			'hi', // Hindi (India)
			'hu-hu',
			'hu', // Hungarian (Hungary)
			'id-id',
			'id', // Indonesian (Indonesia)
			'it-it',
			'it', // Italian (Italy)
			'ja-jp',
			'ja', // Japanese (Japan)
			'tlh', // Klingon (Qo'noS)
			'ko-kr',
			'ko', // Korean (Korea)
			'lt-lt',
			'lt', // Lithuanian (Lithuania)
			'ms-my',
			'ms', // Malay (Malaysia)
			'nb-no',
			'nb', // Norwegian Bokmål (Norway)
			'pl-pl',
			'pl', // Polish (Poland)
			'pt-br',
			'pt', // Portuguese (Brazil)
			'ro-ro',
			'ro', // Romanian (Romania)
			'ru-ru',
			'ru', // Russian (Russia)
			'sr-ba',
			'sr', // Serbian (Bosnia and Herzegovina)
			'sk-sk',
			'sk', // Slovak (Slovakia)
			'sl-si',
			'sl', // Slovenian (Slovenia)
			'es-es',
			'es', // Spanish (Spain)
			'sv-se',
			'sv', // Swedish (Sweden)
			'tl-ph',
			'tl', // Tagalog (Philippines)
			'th-th',
			'th', // Thai (Thailand)
			'tr-tr',
			'tr', // Turkish (Turkey)
			'uk-ua',
			'uk', // Ukrainian (Ukraine)
			'vi-vn',
			'vi' // Vietnamese (Vietnam)
		]

		// Check if language with country is supported
		const isLanguageCountryExist = captchaLanguages.includes(language)
		if (isLanguageCountryExist) return language

		// Check if language is supported
		const isLanguageExist = captchaLanguages.includes(language.slice(0, 2))
		if (isLanguageExist) return getLanguageCode(language)
	}

	// Return default language
	return language
}

export const parseBytes = (bytes: number): string => {
	if (bytes === 0) return '0 B'

	const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
	const i = Math.floor(Math.log(bytes) / Math.log(1024))

	return `${Number.parseFloat((bytes / 1024 ** i).toFixed(2))} ${sizes[i]}`
}

// Debounce utility
export class Debouncer {
	private timerId?: NodeJS.Timeout

	debounce(func: () => void, delay: number): void {
		if (this.timerId) {
			clearTimeout(this.timerId)
		}
		this.timerId = setTimeout(func, delay)
	}
}

// Get unique slogans from appSettings language
export const getUniqueSlogans = () => {
	const slogansSet = new Set()

	getActiveLanguages.forEach(language => {
		slogansSet.add(language.slogan)
	})

	return Array.from(slogansSet)
}

// Check if the passed value is a number
export const isNumber = (x: any) => {
	if (typeof x === 'number' && !Number.isNaN(x)) {
		if (Number.isInteger(x)) {
			return true // Integer
		}
		return true // Float
	}
	return false
}

// Check if the passed value is a boolean
export const isBool = (x: any) => {
	if (typeof x === 'boolean') {
		return true
	}
	return false
}

// Delays by given milliseconds
export const delay = (milliseconds: number | undefined) => new Promise(resolve => setTimeout(resolve, milliseconds))

// Extract title from text
export const extractTitle = (text: string) => {
	// Define punctuation marks that might end a title
	const punctuationMarks = ['.', '?', '!', ':', ';']
	let endIndex = -1

	// Find the earliest position of any punctuation mark
	for (const mark of punctuationMarks) {
		const index = text.indexOf(mark)
		if (index !== -1 && (endIndex === -1 || index < endIndex)) {
			endIndex = index
		}
	}

	// If a punctuation mark is found within the first 160 characters, use it to end the title
	if (endIndex !== -1 && endIndex <= 160) {
		return text.substring(0, endIndex + 1) // Include the punctuation mark
	}

	// If no suitable punctuation mark is found, or it's beyond 160 characters, truncate and add ellipsis
	return text.length <= 160 ? text : text.substring(0, 157) + '...'
}
