import { type ChangeEvent, useEffect, useRef, useState, type JSX } from 'react'
import { createPortal } from 'react-dom'
import './FileManager.scss'

// API
import apiProtected from '../../../api/apiProtected'

// Utilities
import { formatDisplayDate } from '../../../utilities/dateHelper'

// Atoms
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'
import Input from '../../../ruya-shared/shared/ui/atoms/input/Input'
import { Form, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import Checkbox from '../../../ruya-shared/shared/ui/atoms/checkBox/CheckBox'

// Molecules
import InfoBox from '../../../ruya-shared/shared/ui/molecules/infoBox/InfoBox'
import FileInput from '../fileInput/FileInput'

// React Hook Form
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

// Luxon
import { DateTime } from 'luxon'

// Validation
import { newFolderSchema } from '../../../validation/fileManagerSchema'

// Localization
import { useTranslation } from 'react-i18next'

// Toast
import { toast } from 'react-toastify'

// Shared
import { parseBytes } from '../../../ruya-shared/shared/utils/commonHelper'
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'
import { apiErrorHandler } from '../../../ruya-shared/shared/utils/errorHelper'

// TODO: Breadcrumbs should be clickable
// TODO: Implement rename file

const FileManager = (props: FileManagerProps) => {
	// Destruct props
	const { onSelect, onClose } = props
	const defaultFolder = props.defaultFolder || '/'

	// State
	const [currentFolderList, setCurrentFolderList] = useState<FileManagerFolderList>({ directories: [], files: [] })
	const [currentFolder, setCurrentFolder] = useState<string[]>([])
	const [selectedFile, setSelectedFile] = useState<FileManagerFile | null>(null)
	const [createNewFolderModal, setCreateNewFolderModal] = useState(false)
	const [uploadFileModal, setUploadFileModal] = useState(false)
	const [openedMenu, setOpenedMenu] = useState<string | null>(null)

	// Translation Hook
	const { t } = useTranslation()

	useEffect(() => {
		if (defaultFolder) {
			setCurrentFolder(splitFolderPath(defaultFolder))
		}
	}, [defaultFolder])

	// Load folder and file list on load and when currentFolder changes
	useEffect(() => {
		loadFolder()
		setOpenedMenu(null)
	}, [currentFolder])

	// Loads folder content
	const loadFolder = async () => {
		setSelectedFile(null)
		const response = await getFolderContent(currentFolder.join(''))
		setCurrentFolderList(response)
	}

	// Handle folder click
	const goFolder = (directory: string) => {
		const lastFolder = directory.split('/').filter(Boolean).pop() as string
		setCurrentFolder([...currentFolder, lastFolder + '/'])
		setSelectedFile(null)
	}

	const goBack = () => {
		if (currentFolder.length > 0) {
			const newCurrentFolder = [...currentFolder]
			newCurrentFolder.pop()
			setCurrentFolder(newCurrentFolder)
		}
	}

	// Handle file click
	const handleFile = (file: FileManagerFile) => () => {
		setSelectedFile(file)
	}

	// Split folder path by given full path
	const splitFolderPath = (folderPath: string): string[] => {
		const folders = folderPath.split('/').filter(path => path.length > 0)
		const folderArray = folders.map(folder => folder + '/')
		return folderArray
	}

	return (
		<div className="FileManager">
			<div className="FileManager_Header">
				<div className="FileManager_Header_Bar">
					<h1>{t('admin:fileManagerHeader')}</h1>
					<MaterialSymbol name="close" onClick={() => onClose()} />
				</div>
				<div className="FileManager_Header_Directory">
					<MaterialSymbol name="chevron_right" /> /{currentFolder.join('')}
				</div>
			</div>
			<div className="FileManager_Body">
				<div className="FileManager_Body_List">
					<div className="FileManager_Body_Nav">
						<div className="FileManager_Body_Nav_Back" title="Back to parent directory">
							{currentFolder.length > 0 && (
								<div onClick={goBack}>
									<MaterialSymbol name="arrow_upward" /> {t('links:back')}
								</div>
							)}
						</div>
						<div className="FileManager_Body_Nav_NewFolder" onClick={() => setCreateNewFolderModal(true)}>
							<MaterialSymbol name="create_new_folder" title="Add New Folder" />
						</div>
						<div className="FileManager_Body_Nav_Upload" onClick={() => setUploadFileModal(true)}>
							<MaterialSymbol name="upload_file" title="Upload File" />
						</div>
					</div>
					<ul>
						{currentFolderList.directories?.map((directory: string) => (
							<FileManagerFolder
								key={directory}
								setCurrentFolder={setCurrentFolder}
								currentFolder={currentFolder}
								folderName={directory}
								openedMenu={openedMenu}
								setOpenedMenu={setOpenedMenu}
								onClick={() => goFolder(directory)}
							/>
						))}
						{currentFolderList.files?.map((file: FileManagerFile) => (
							<FileManagerFile
								key={file.key}
								file={file}
								onClick={handleFile}
								loadFolder={loadFolder}
								setSelectedFile={setSelectedFile}
								openedMenu={openedMenu}
								setOpenedMenu={setOpenedMenu}
								selected={selectedFile?.key === file.key}
								onSelect={onSelect}
							/>
						))}
					</ul>
				</div>

				<div className="FileManager_Body_Preview">
					{selectedFile && (
						<>
							<div className="FileManager_Body_Info">
								<div className="FileManager_Body_Info_Name">{selectedFile.key.split('/').filter(Boolean).pop()}</div>
								<div className="FileManager_Body_Info_Size">{parseBytes(selectedFile.size)} </div>
								<div className="FileManager_Body_Info_LastModified">
									{formatDisplayDate(selectedFile.lastModified, DateTime.DATETIME_FULL_WITH_SECONDS)}
								</div>
							</div>
							<div className="FileManager_Body_Item">
								<PreviewFile file={selectedFile} />
							</div>
						</>
					)}
				</div>
			</div>
			{createNewFolderModal && (
				<CreateNewFolder
					setCreateNewFolderModal={setCreateNewFolderModal}
					folderList={currentFolderList.directories}
					currentFolder={currentFolder}
					loadFolder={loadFolder}
				/>
			)}
			{uploadFileModal && (
				<UploadFile setUploadFileModal={setUploadFileModal} currentFolder={currentFolder} loadFolder={loadFolder} />
			)}
		</div>
	)
}

const FileManagerFolder = (props: FileManagerFolderProps): JSX.Element => {
	// Destruct props
	const { folderName, onClick, currentFolder, setCurrentFolder, openedMenu, setOpenedMenu } = props

	// State
	const contextMenuRef = useRef<HTMLDivElement | null>(null)

	// Translation Hook
	const { t } = useTranslation()

	// Effect to add event listener to document
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (contextMenuRef.current && !contextMenuRef.current.contains(event.target as Node)) {
				closeContextMenu()
			}
		}

		document.addEventListener('click', handleClickOutside)
		return () => {
			document.removeEventListener('click', handleClickOutside)
		}
	}, [])

	const openContextMenu = (e: React.MouseEvent<HTMLLIElement>) => {
		e.preventDefault() // prevent default browser context menu
		setOpenedMenu(folderName)
	}

	const closeContextMenu = () => {
		setOpenedMenu(null)
	}

	const handleDelete = async () => {
		if (window.confirm(t('dialog:deleteFolder'))) {
			const deleteFolderResponse = await deleteFolder(folderName)

			if (deleteFolderResponse.status === 'success') {
				// Show success message
				toast.success(t('info:folderDeleted'), {
					position: 'top-center',
					autoClose: 2000
				})
			} else {
				// Show error message
				toast.error(deleteFolderResponse.message || t('errors:cantDeleteFolder'), {
					position: 'top-center',
					autoClose: 2000
				})
			}

			setCurrentFolder(currentFolder) // Refresh the current folder's content
		}
	}

	return (
		<li className="FileManager_Folder" onClick={onClick} onContextMenu={openContextMenu}>
			<MaterialSymbol name="folder" /> {folderName.split('/').filter(Boolean).pop()}
			{openedMenu === folderName && (
				<div className="FileManager_ContextMenu" ref={contextMenuRef}>
					<ul>
						{/* <li>
							<MaterialSymbol name="bookmark_manager" /> Rename
						</li> */}
						<li onClick={handleDelete}>
							<MaterialSymbol name="folder_delete" /> Delete
						</li>
					</ul>
				</div>
			)}
		</li>
	)
}

const FileManagerFile = (props: FileManagerFileProps): JSX.Element => {
	// Destruct props
	const { file, onClick, loadFolder, setSelectedFile, selected, openedMenu, setOpenedMenu, onSelect } = props

	const [fileDetails, setFileDetails] = useState<FileManagerFile | null>(null)

	// State
	const contextMenuRef = useRef<HTMLDivElement | null>(null)

	// Translation Hook
	const { t } = useTranslation()

	const getFileDetails = (key: string) => {
		apiProtected.get(`/admin/file-manager/file-metadata/${key}`).then(response => {
			if (response.data.status === 'success') {
				setFileDetails(response.data.data as FileManagerFile)
			} else {
				setFileDetails(null)
				console.warn(response.data.message)
			}
		})
	}

	// Effect to add event listener to document
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (contextMenuRef.current && !contextMenuRef.current.contains(event.target as Node)) {
				closeContextMenu()
			}
		}

		document.addEventListener('click', handleClickOutside)
		return () => {
			document.removeEventListener('click', handleClickOutside)
		}
	}, [])

	const openContextMenu = (e: React.MouseEvent<HTMLLIElement>) => {
		e.preventDefault() // prevent default browser context menu
		getFileDetails(file.key)
		setOpenedMenu(file.key)
	}

	const closeContextMenu = () => {
		setOpenedMenu(null)
	}

	const handleDelete = async () => {
		if (window.confirm(t('dialog:deleteFile'))) {
			const isDeleted = await deleteFile(file.key)
			if (isDeleted) {
				setSelectedFile(null)
				loadFolder() // Refresh the current folder's content
				window.alert(t('info:fileDeleted'))
			} else {
				window.alert(t('error:cantDeleteFile'))
			}
		}
	}

	return (
		<li
			className={`FileManager_File${selected ? ' FileManager_File--selected' : ''}`}
			onClick={onClick(file)}
			onContextMenu={openContextMenu}>
			<MaterialSymbol name="draft" /> {file.key.split('/').filter(Boolean).pop()}
			{openedMenu === file.key && (
				<div className="FileManager_ContextMenu" ref={contextMenuRef}>
					<ul>
						{/* <li>
							<MaterialSymbol name="edit_document" /> Rename
						</li> */}
						{fileDetails && (
							<li className="FileManager_ContextMenu_Import" onClick={() => onSelect(fileDetails)}>
								<MaterialSymbol name="file_save" /> Import
							</li>
						)}
						<li onClick={handleDelete}>
							<MaterialSymbol name="scan_delete" /> Delete
						</li>
					</ul>
				</div>
			)}
		</li>
	)
}

const getFolderContent = async (folder: string | undefined): Promise<FileManagerFolderList> => {
	const response = (await apiProtected.get(`/admin/file-manager/list-files${folder ? '?prefix=' + folder : ''}`)) as ApiResponse<any>

	if (response.data.status === 'success') {
		return response.data.data as FileManagerFolderList
	}
	return { directories: [], files: [] }
}

const deleteFile = async (key: string): Promise<boolean> => {
	const response = (await apiProtected.delete(`/admin/file-manager/delete-file/${key}`)) as ApiResponse<any>

	if (response.data.status === 'success') {
		return true
	}
	return false
}

const deleteFolder = async (key: string): Promise<ApiResponse> => {
	const response = (await apiProtected.delete(`/admin/file-manager/delete-folder/${key}`)) as ApiResponse<any>

	return response.data
}

const PreviewFile = (props: PreviewFileProps) => {
	const { file } = props

	const [fileMetadata, setFileMetadata] = useState<FileManagerFile | null>(null)
	const [apiError, setApiError] = useState<string | null>(null)

	useEffect(() => {
		apiProtected.get(`/admin/file-manager/file-metadata/${file.key}`).then(response => {
			if (response.data.status === 'success') {
				setFileMetadata(response.data.data as FileManagerFile)
			} else {
				setApiError(response.data.message)
			}
		})
	}, [file])

	if (!fileMetadata) return <p>Loading...</p>

	if (apiError) return <p>{apiError}</p>

	// Image
	if (commonSettings.apps.cdn.supportedImageFormats.includes(fileMetadata.contentType)) {
		return <PreviewImage file={fileMetadata} />
	}

	// Video
	if (commonSettings.apps.cdn.supportedVideoFormats.includes(fileMetadata.contentType)) {
		return <PreviewVideo file={fileMetadata} />
	}

	// File
	if (commonSettings.apps.cdn.supportedFileFormats.includes(fileMetadata.contentType)) {
		return <NoPreview file={fileMetadata} />
	}
}

const PreviewImage = (props: PreviewFileProps) => {
	const { file } = props

	return <img src={commonSettings.apps.cdn.url + '/' + file.key} alt="Preview" />
}

const PreviewVideo = (props: PreviewFileProps) => {
	const { file } = props
	return (
		<video width="100%" controls>
			<source src={commonSettings.apps.cdn.url + '/' + file.key} type={file.contentType} />
			Your browser does not support the video tag.
		</video>
	)
}

const NoPreview = (props: PreviewFileProps) => {
	const { file } = props
	return (
		<div>
			<div>Key: ${file.key}</div>
			<div>Size: ${file.size} bytes </div>
			<div>Last Modified: {formatDisplayDate(file.lastModified, DateTime.DATETIME_FULL_WITH_SECONDS)}</div>
			<div>Content Type: ${file.contentType} </div>
			<div>ETag: ${file.eTag} </div>
		</div>
	)
}

const CreateNewFolder = (props: CreateNewFolderProps) => {
	const { setCreateNewFolderModal, folderList, currentFolder, loadFolder } = props

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<string | null>(null)

	// Translation Hook
	const { t } = useTranslation()

	// React Hook Form
	const formOptions = { mode: 'onChange', resolver: yupResolver<NewFolderFormValues>(newFolderSchema) } as any
	const {
		register,
		handleSubmit,
		control,
		formState: { errors, isValid }
	} = useForm<NewFolderFormValues>(formOptions)

	// Form submit
	const onSubmit = async (values: NewFolderFormValues) => {
		// Set loading
		setError(null)
		setLoading(true)

		// Full folder path
		const fullFolderPath = currentFolder.join('') + values.folderName

		if (folderList.includes(fullFolderPath)) {
			setError(t('form:folderName.unique'))
			setLoading(false)
		} else {
			const response = await apiProtected.post('/admin/file-manager/create-folder', { folderPath: fullFolderPath })
			if (response.data.status === 'success') {
				loadFolder()
				setError(null)
				setLoading(false)
				setCreateNewFolderModal(false)
			} else {
				setLoading(false)
				setError(t('form:folderName.cantCreateFolder'))
			}
		}
	}

	const handleContentClick = (e: React.MouseEvent) => {
		e.stopPropagation()
	}

	return (
		<div className="FileManager_Dialog" onClick={() => setCreateNewFolderModal(false)}>
			<div className="FileManager_Dialog_Content" onClick={handleContentClick}>
				<Form className="FileManager_Dialog_NewFolderForm" noValidate>
					<FormGroup>
						<Input
							type="text"
							placeholder={t('form:folderName.placeholder')}
							label={t('form:folderName.label')}
							invalid={Boolean(errors?.folderName?.message)}
							invalidMessage={errors?.folderName?.message}
							{...register('folderName')}
						/>
					</FormGroup>

					{error && (
						<FormGroup>
							<InfoBox icon="true" type="error" text={error || t('error:unexpectedError')} />
						</FormGroup>
					)}

					<FormGroup>
						<Button
							type="button"
							onClick={handleSubmit(onSubmit)}
							disabled={loading || !isValid}
							loading={loading}
							text={t('button:save')}
							loadingText={t('button:loading')}
							block
						/>
					</FormGroup>
				</Form>
			</div>
		</div>
	)
}

const UploadFile = (props: UploadFileProps) => {
	const { setUploadFileModal, currentFolder, loadFolder } = props
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<string | null>(null)
	const [overwrite, setOverwrite] = useState<boolean>(false)
	const fileInputRef = useRef<HTMLInputElement | null>(null)

	// Translation Hook
	const { t } = useTranslation()

	const handleContentClick = (e: React.MouseEvent) => {
		e.stopPropagation()
	}

	const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
		setOverwrite(event.target.checked)
	}

	const resetInput = () => {
		if (fileInputRef.current) {
			fileInputRef.current.value = ''
		}
	}

	const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		setError(null)

		if (e.target.files && e.target.files.length > 0) {
			setLoading(true)
			const file = e.target.files[0]

			// File path
			const filePath = currentFolder.join('')

			// Upload logic here (e.g., call to your API to upload the file)
			try {
				const formData = new FormData()
				formData.append('file', file)
				formData.append('filePath', filePath)
				formData.append('overwrite', `${overwrite}`)

				const response = await apiProtected.post('/admin/file-manager/upload-file', formData)

				if (response.data.status === 'success') {
					loadFolder()
					setLoading(false)
					setUploadFileModal(false)
				} else {
					setLoading(false)
					setError(t('form:file.cantUploadFile'))
					resetInput()
				}
			} catch (error) {
				setLoading(false)
				setError(apiErrorHandler(error))
				resetInput()
			}
		}
	}

	return (
		<div className="FileManager_Dialog" onClick={() => setUploadFileModal(false)}>
			<div className="FileManager_Dialog_Content" onClick={handleContentClick}>
				<div className="FileManager_Dialog_UploadFile">
					<FormGroup>
						<Checkbox label={t('form:overwrite.label')} value={overwrite} onChange={handleCheckboxChange} />
					</FormGroup>
					<FormGroup>
						<FileInput loading={loading} onChange={handleFileChange} ref={fileInputRef} />
					</FormGroup>
				</div>

				{error && <InfoBox icon="true" type="error" text={error} />}
			</div>
		</div>
	)
}

export const FileManagerModal = (props: FileManagerModalProps) => {
	const { isOpen, onClose, onSelect } = props
	const defaultFolder = props.defaultFolder || '/'

	const modalRoot = document.createElement('modal-root')

	useEffect(() => {
		document.body.appendChild(modalRoot)
		return () => {
			document.body.removeChild(modalRoot)
		}
	}, [modalRoot])

	if (!isOpen) return null

	return createPortal(
		<div className="Admin_Modal">
			<div onClick={e => e.stopPropagation()} className="Admin_Modal_Content">
				<FileManager onSelect={onSelect} onClose={onClose} defaultFolder={defaultFolder} />
			</div>
		</div>,
		modalRoot
	)
}

export default FileManager
