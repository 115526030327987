import { useEffect, useState } from 'react'
import './Plans.scss'

// Templates
import Admin from '../../templates/admin/Admin'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate } from 'react-router-dom'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import Select from '../../../ruya-shared/shared/ui/atoms/select/Select'
import Loading from '../../../ruya-shared/shared/ui/atoms/loading/Loading'

// Molecules
import InfoBox from '../../../ruya-shared/shared/ui/molecules/infoBox/InfoBox'

// Zustand
import usePlanStore from '../../../store/planStore'

// Types
import type { IPlan } from '../../../ruya-shared/shared/types'
import type { IPlanSearchParams } from '../../../@types/plan'

const Plans = () => {
	// Router
	const navigate = useNavigate()

	// Post Search Params
	const initialPlanSearchParams: IPlanSearchParams = {
		active: 'all'
	}

	// Search Params
	const [planSearchParams, setPlanSearchParams] = useState<IPlanSearchParams>(initialPlanSearchParams)

	// Translation Hook
	const { t } = useTranslation()

	// Zustand
	const planStore = usePlanStore()

	// Get Posts
	useEffect(() => {
		planStore.loadPlanList(planSearchParams.active)
	}, [planSearchParams])

	// Status filter change
	const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setPlanSearchParams({
			...planSearchParams,
			active: e.target.value as IPlanSearchParams['active']
		})
	}

	// Create Plan
	const handleCreatePlan = () => {
		navigate('/plans/add')
	}

	// Loading
	if (planStore.loading)
		return (
			<main className="Plans">
				<Loading />
			</main>
		)

	// Error
	if (planStore.error)
		return (
			<main className="Plans">
				<InfoBox icon="true" type="error" text={planStore.error} />
			</main>
		)

	return (
		<main className="Plans">
			<h1 className="Admin_H1">{t('admin:headerPlans')}</h1>

			<div className="Plans_Bar">
				<Button text={t('button:addPlan')} onClick={handleCreatePlan} />
			</div>

			<div className="Plans_List">
				<table className="Plans_List_Table">
					<thead>
						<tr className="Plans_List_Row">
							<th className="Plans_List_Title">{t('admin:plan')}</th>
							<th className="Plans_List_Description">{t('admin:planDescription')}</th>
							<th className="Plans_List_Status">
								<Select inputSize="xs" onChange={handleStatusChange} value={planSearchParams.active}>
									<option value="all">{t('options:all')}</option>
									<option value="1">{t('options:active')}</option>
								</Select>
							</th>
							
						</tr>
					</thead>
					<tbody className="Plans_List_Body">
						{planStore?.planList?.map((plan: IPlan, index) => {
							const key = plan?._id ? plan._id.toString() : index

							return (
								<tr
									className={`Plans_List_Body_Row${plan.active ? '' : ' Plans_List_Body_Row--disabled'}`}
									key={key}
									onClick={() => navigate(`/plans/edit/${plan._id}`)}>
									<th className="Plans_List_Title">{plan.name}</th>
									<th className="Plans_List_Description">{plan.description}</th>
									<th className="Plans_List_Status">{plan.active ? t('options:active') : t('options:disabled')}</th>
									
								</tr>
							)
						})}
					</tbody>
				</table>
				{!planStore?.planList?.length && <InfoBox icon="true" type="info" text={t('admin:noRecordsFound')} />}
			</div>
		</main>
	)
}

export default Admin(Plans)
