import { forwardRef, useCallback, useEffect, useRef, useState, type JSX } from 'react'
import './ArticleEditor.scss'

// Hooks
import useScript from '../../../hooks/useScript'

// File Manager Modal
import { FileManagerModal } from '../fileManager/FileManager'

// Shared
import { generateRandomLetters } from '../../../ruya-shared/shared/utils/commonHelper'
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

interface ArticleEditorProps {
	onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
	onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void
	dir: 'rtl' | 'ltr'
	value?: string
	name: string
	defaultFolder?: string
	contentCss?: string
}

const ArticleEditor = forwardRef<HTMLTextAreaElement, ArticleEditorProps>((props: ArticleEditorProps, ref): JSX.Element => {
	// File Manager state
	const [isFileManagerOpen, setFileManagerOpen] = useState(false)
	const [selectedFile, setSelectedFile] = useState<FileManagerFile | null>(null)
	const [editorId] = useState(props.name || `ArticleEditor${generateRandomLetters()}`)
	const defaultFolder = props.defaultFolder || '/'
	const content_css = props.contentCss || '../../../tinymce/articles.css'

	// Add TinyMCE script to the head
	const isScriptLoaded = useScript(process.env.PUBLIC_URL + '/tinymce/tinymce.min.js')

	const editorRef = useRef<any>(null)

	// Init TinyMCE
	useEffect(() => {
		if (!isScriptLoaded) return

		if (typeof (window as any).tinymce !== 'undefined') {
			;(window as any).tinymce.PluginManager.add('FileManager', function (editor: any) {
				editor.ui.registry.addButton('FileManager', {
					text: 'File Manager',
					icon: 'gallery',
					onAction: function () {
						setFileManagerOpen(true)
					}
				})
			})
			;(window as any).tinymce.init({
				height: 500,
				base_url: `${process.env.PUBLIC_URL}/tinymce`,
				suffix: '.min',
				selector: `#${editorId}`,
				plugins: [
					'FileManager',
					'advlist',
					'autolink',
					'lists',
					'link',
					'image',
					'charmap',
					'preview',
					'anchor',
					'searchreplace',
					'visualblocks',
					'code',
					'fullscreen',
					'insertdatetime',
					'media',
					'table',
					'paste',
					'imagetools',
					'wordcount'
				],
				menubar: 'insert',
				toolbar:
					'FileManager | undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | blockquote | code | removeformat | fullscreen',
				directionality: props.dir,
				content_css,
				init_instance_callback: (editor: any) => {
					editorRef.current = editor // Storing editor instance in the ref

					editor.on('change', function (e: any) {
						const newContent = editor.getContent()

						props.onChange?.({
							target: { name: props.name, value: newContent } as any,
							type: 'change',
							nativeEvent: new Event('', {}),
							currentTarget: { name: props.name, value: newContent } as any,
							bubbles: false,
							cancelable: false,
							defaultPrevented: false,
							eventPhase: 0,
							isTrusted: false,
							preventDefault: function (): void {
								throw new Error('Function not implemented.')
							},
							isDefaultPrevented: function (): boolean {
								throw new Error('Function not implemented.')
							},
							stopPropagation: function (): void {
								throw new Error('Function not implemented.')
							},
							isPropagationStopped: function (): boolean {
								throw new Error('Function not implemented.')
							},
							persist: function (): void {
								throw new Error('Function not implemented.')
							},
							timeStamp: 0
						})
					})

					editor.on('blur', () => {
						if (props.onBlur) {
							const event = {
								target: {
									name: props.name,
									value: editor.getContent()
								},
								type: 'blur'
							} as React.FocusEvent<HTMLTextAreaElement>

							props.onBlur(event)
						}
					})
				}
			})
		}

		return () => {
			if (typeof (window as any).tinymce !== 'undefined') {
				;(window as any).tinymce.remove(`#${editorId}`)
			}
		}
	}, [isScriptLoaded, editorId])

	// Update value
	useEffect(() => {
		if (editorRef.current && props.value !== undefined) {
			editorRef.current.setContent(props.value)
		}
	}, [props.value, editorRef.current])

	// Insert selected file
	useEffect(() => {
		if (selectedFile && editorRef.current) {
			const fileUrl = commonSettings.apps.cdn.url + '/' + selectedFile.key
			let insertedContent = null

			if (commonSettings.apps.cdn.supportedImageFormats.includes(selectedFile.contentType)) {
				insertedContent = `<img src="${fileUrl}" />`
			}

			if (commonSettings.apps.cdn.supportedVideoFormats.includes(selectedFile.contentType)) {
				insertedContent = '<video src="' + fileUrl + '"/>'
			}

			if (insertedContent) {
				editorRef.current.focus()
				editorRef.current.insertContent(insertedContent)

				// Add this part to call the provided onChange with the new content
				const newContent = editorRef.current.getContent()
				props.onChange?.({
					target: { name: props.name, value: newContent } as any,
					type: 'change',
					nativeEvent: new Event('', {}),
					currentTarget: { name: props.name, value: newContent } as any,
					bubbles: false,
					cancelable: false,
					defaultPrevented: false,
					eventPhase: 0,
					isTrusted: false,
					preventDefault: function (): void {
						throw new Error('Function not implemented.')
					},
					isDefaultPrevented: function (): boolean {
						throw new Error('Function not implemented.')
					},
					stopPropagation: function (): void {
						throw new Error('Function not implemented.')
					},
					isPropagationStopped: function (): boolean {
						throw new Error('Function not implemented.')
					},
					persist: function (): void {
						throw new Error('Function not implemented.')
					},
					timeStamp: 0
				})
			}
		}
	}, [selectedFile, editorRef])

	// Handle file select
	const handleFileSelect = useCallback((fileInfo: FileManagerFile) => {
		setSelectedFile(fileInfo)
		setFileManagerOpen(false)
	}, [])

	return (
		<div className="ArticleEditor">
			<textarea id={editorId} name={props.name} ref={ref} />
			<FileManagerModal
				isOpen={isFileManagerOpen}
				defaultFolder={defaultFolder}
				onClose={() => setFileManagerOpen(false)}
				onSelect={handleFileSelect}
			/>
		</div>
	)
})

ArticleEditor.displayName = 'ArticleEditor'
export default ArticleEditor
