import { useEffect } from 'react'
import './Statistics.scss'

// Store
import useStatisticsStore from '../../../store/statisticsStore'

// Atoms
import H2 from '../../../ruya-shared/shared/ui/atoms/h2/H2'

const Statistics = () => {
  const statisticsStore = useStatisticsStore()

  useEffect(() => {
    // Load statistics
    statisticsStore.loadStatistics()
  }, [])

  return (
    <div className="Statistics">
      <H2>Total Statistics</H2>
      <table className="Statistics_Table">
        <tbody>
          <tr>
            <th>Total Users</th>
            <td>{statisticsStore?.statistics?.totalUsers}</td>
          </tr>
          <tr>
            <th>Total Timeline Entries</th>
            <td>{statisticsStore?.statistics?.totalTimelineItems}</td>
          </tr>
          <tr>
            <th>Total Diaries</th>
            <td>{statisticsStore?.statistics?.totalTimelineDiaryItems}</td>
          </tr>
          <tr>
            <th>Total Dreams</th>
            <td>{statisticsStore?.statistics?.totalTimelineDreamItems}</td>
          </tr>
          <tr>
            <th>Total Life Events</th>
            <td>{statisticsStore?.statistics?.totalTimelineEventItems}</td>
          </tr>
          <tr>
            <th>Total Interpretations</th>
            <td>{statisticsStore?.statistics?.totalDreamInterpretations}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Statistics
