// Router
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'

// Private Route
import Private from './Private'

// Public pages
import Login from '../ui/pages/login/Login'

// Private pages
import Logout from '../ui/pages/logout/Logout'

// Admin pages
import Dashboard from '../ui/pages/dashboard/Dashboard'

import Users from '../ui/pages/users/Users'

import Articles from '../ui/pages/articles/Articles'
import AddArticle from '../ui/pages/addArticle/AddArticle'
import EditArticle from '../ui/pages/editArticle/EditArticle'
import EditArticleImages from '../ui/pages/editArticleImages/EditArticleImages'
import EditArticleAudio from '../ui/pages/editArticleAudio/EditArticleAudio'
import EditArticleTranslations from '../ui/pages/editArticleTranslations/EditArticleTranslations'
import EditArticleSettings from '../ui/pages/editArticleSettings/EditArticleSettings'

import Pages from '../ui/pages/pages/Pages'
import AddPage from '../ui/pages/addPage/AddPage'
import EditPage from '../ui/pages/editPage/EditPage'
import EditPageTranslations from '../ui/pages/editPageTranslations/EditPageTranslations'
import EditPageSettings from '../ui/pages/editPageSettings/EditPageSettings'

import Plans from '../ui/pages/plans/Plans'
import AddPlan from '../ui/pages/addPlan/AddPlan'

import Theories from '../ui/pages/theories/Theories'
import AddTheory from '../ui/pages/addTheory/AddTheory'
import EditTheory from '../ui/pages/editTheory/EditTheory'
import EditTheoryTranslations from '../ui/pages/editTheoryTranslations/EditTheoryTranslations'

// Shared
import { commonSettings } from '../ruya-shared/shared/config/commonSettings'

// Router config
const AppRouter = () => {
	return (
		<Router>
			<Routes>
				{/* Public routes */}
				<Route path={commonSettings.apps.admin.paths.home} element={<Login />} />
				<Route path={commonSettings.apps.admin.paths.login} element={<Login />} />
				<Route path={commonSettings.apps.admin.paths.logout} element={<Logout />} />

				{/* Admin routes */}
				<Route path="/" element={<Navigate to={commonSettings.apps.admin.paths.dashboard} />} />
				<Route path={commonSettings.apps.admin.paths.dashboard} element={<Private Component={Dashboard} roles={['admin', 'moderator']} />} />

				<Route path="/page" element={<Private Component={Pages} roles={['admin', 'moderator']} />} />
				<Route path="/page/add" element={<Private Component={AddPage} roles={['admin', 'moderator']} />} />
				<Route path="/page/edit/:id" element={<Private Component={EditPage} roles={['admin', 'moderator']} />} />
				<Route path="/page/edit/:id/translations" element={<Private Component={EditPageTranslations} roles={['admin', 'moderator']} />} />
				<Route path="/page/edit/:id/settings" element={<Private Component={EditPageSettings} roles={['admin', 'moderator']} />} />

				<Route path="/article" element={<Private Component={Articles} roles={['admin', 'moderator']} />} />
				<Route path="/article/add" element={<Private Component={AddArticle} roles={['admin', 'moderator']} />} />
				<Route path="/article/edit/:id" element={<Private Component={EditArticle} roles={['admin', 'moderator']} />} />
				<Route path="/article/edit/:id/images" element={<Private Component={EditArticleImages} roles={['admin', 'moderator']} />} />
				<Route path="/article/edit/:id/audio" element={<Private Component={EditArticleAudio} roles={['admin', 'moderator']} />} />
				<Route path="/article/edit/:id/translations" element={<Private Component={EditArticleTranslations} roles={['admin', 'moderator']} />} />
				<Route path="/article/edit/:id/settings" element={<Private Component={EditArticleSettings} roles={['admin', 'moderator']} />} />

				<Route path="/users" element={<Private Component={Users} roles={['admin', 'moderator']} />} />

				<Route path="/theories" element={<Private Component={Theories} roles={['admin', 'moderator']} />} />
				<Route path="/theories/add" element={<Private Component={AddTheory} roles={['admin', 'moderator']} />} />
				<Route path="/theories/edit/:id" element={<Private Component={EditTheory} roles={['admin', 'moderator']} />} />
				<Route path="/theories/edit/:id/translations" element={<Private Component={EditTheoryTranslations} roles={['admin', 'moderator']} />} />

				<Route path="/plans" element={<Private Component={Plans} roles={['admin', 'moderator']} />} />
				<Route path="/plans/add" element={<Private Component={AddPlan} roles={['admin', 'moderator']} />} />
			</Routes>
		</Router>
	)
}

export default AppRouter
