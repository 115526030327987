import { useState } from 'react'
import './AddPage.scss'

// Templates
import Admin from '../../templates/admin/Admin'

// Localization
import { useTranslation } from 'react-i18next'

// React Hook Form
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DevTool } from '@hookform/devtools'
import { pageSchema } from '../../../validation/pageSchema'

// Atoms
import { Form, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import Switch from '../../../ruya-shared/shared/ui/atoms/switch/Switch'
import Input from '../../../ruya-shared/shared/ui/atoms/input/Input'
import HistoryBackLink from '../../../ruya-shared/shared/ui/atoms/historyBackLink/HistoryBackLink'

// Molecules
import InfoBox from '../../../ruya-shared/shared/ui/molecules/infoBox/InfoBox'
import ArticleEditor from '../../molecules/articleEditor/ArticleEditor'

// Zustand
import usePageStore from '../../../store/pageStore'

// Router
import { useNavigate } from 'react-router-dom'

// Shared
import languageSettings from '../../../ruya-shared/shared/config/languageSettings'
import type { IPage } from '../../../ruya-shared/shared/types'

const AddPage = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Zustand
	const pageStore = usePageStore()

	// Router
	const navigate = useNavigate()

	// API Error State
	const [apiError, setApiError] = useState<string | undefined>(undefined)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	// React Hook Form
	const defaultValues: Partial<IPage> = {
		lang: languageSettings.defaultLanguage,
		slug: '',
		linkLabel: '',
		title: '',
		content: '',
		metaTitle: '',
		metaDescription: '',
		metaKeywords: '',
		showInMainMenu: false,
		showInFooterMenu: false,
		menuOrder: 0,
		pageTag: ''
	}
	const formOptions = { defaultValues, mode: 'onChange', resolver: yupResolver(pageSchema) } as any
	const {
		register,
		handleSubmit,
		control,
		watch,
		getValues,
		setValue,
		formState: { errors, isValid }
	} = useForm<IPage>(formOptions)

	// Watch
	const slugValue = watch('slug')
	const langValue = watch('lang')

	// Form submit
	const onSubmit = async (values: IPage) => {
		// Set loading
		setIsLoading(true)

		// Clear API error
		setApiError(undefined)

		// Add page
		const response = await pageStore.addPage(values)

		if (response.status === 'success') {
			navigate('/page')
		} else {
			setIsLoading(false)
			setApiError(response.message)
		}
	}

	return (
		<main className="AddPage">
			<h1 className="Admin_H1">{t('admin:headerAddPage')}</h1>

			<HistoryBackLink />

			<Form className="AddPage_Form" noValidate>
				<FormGroup>
					<InfoBox icon="true" type="info" text={`http://ruya.co/${langValue}/pages/${slugValue}`} />
				</FormGroup>

				<FormGroup fourColumn={true}>
					<Input
						inputSize="sm"
						icon="translate"
						type="text"
						placeholder={t('form:language.placeholder')}
						label={t('form:language.label')}
						invalid={Boolean(errors?.lang?.message)}
						invalidMessage={errors?.lang?.message?.toString()}
						disabled={true}
						{...register('lang')}
					/>
					<Input
						inputSize="sm"
						type="text"
						placeholder={t('form:pageTag.placeholder')}
						label={t('form:pageTag.label')}
						invalid={Boolean(errors?.pageTag?.message)}
						invalidMessage={errors?.pageTag?.message?.toString()}
						{...register('pageTag')}
					/>
					<Input
						inputSize="sm"
						icon="link"
						type="text"
						placeholder={t('form:slug.placeholder')}
						label={t('form:slug.label')}
						invalid={Boolean(errors?.slug?.message)}
						invalidMessage={errors?.slug?.message?.toString()}
						{...register('slug')}
					/>
					<Input
						inputSize="sm"
						icon="link"
						type="text"
						placeholder={t('form:linkLabel.placeholder')}
						label={t('form:linkLabel.label')}
						invalid={Boolean(errors?.linkLabel?.message)}
						invalidMessage={errors?.linkLabel?.message?.toString()}
						{...register('linkLabel')}
					/>
				</FormGroup>

				<FormGroup twoColumn={true}>
					<Input
						inputSize="sm"
						type="text"
						placeholder={t('form:metaTitle.placeholder')}
						label={t('form:metaTitle.label')}
						invalid={Boolean(errors?.metaTitle?.message)}
						invalidMessage={errors?.metaTitle?.message?.toString()}
						{...register('metaTitle')}
					/>
					<Input
						inputSize="sm"
						type="text"
						placeholder={t('form:metaDescription.placeholder')}
						label={t('form:metaDescription.label')}
						invalid={Boolean(errors?.metaDescription?.message)}
						invalidMessage={errors?.metaDescription?.message?.toString()}
						{...register('metaDescription')}
					/>
				</FormGroup>

				<FormGroup>
					<Input
						inputSize="sm"
						type="text"
						placeholder={t('form:metaKeywords.placeholder')}
						label={t('form:metaKeywords.label')}
						invalid={Boolean(errors?.metaKeywords?.message)}
						invalidMessage={errors?.metaKeywords?.message?.toString()}
						{...register('metaKeywords')}
					/>
				</FormGroup>

				<FormGroup threeColumn={true}>
					<Switch label={t('form:showInMainMenu.label')} defaultValue={watch('showInMainMenu')} {...register('showInMainMenu')} />
					<Switch
						label={t('form:showInFooterMenu.label')}
						defaultValue={watch('showInFooterMenu')}
						{...register('showInFooterMenu')}
					/>
					<Input
						inputSize="sm"
						type="number"
						placeholder={t('form:menuOrder.placeholder')}
						label={t('form:menuOrder.label')}
						invalid={Boolean(errors?.menuOrder?.message)}
						invalidMessage={errors?.menuOrder?.message?.toString()}
						{...register('menuOrder')}
					/>
				</FormGroup>

				<FormGroup>
					<Input
						type="text"
						placeholder={t('form:title.placeholder')}
						label={t('form:title.label')}
						invalid={Boolean(errors?.title?.message)}
						invalidMessage={errors?.title?.message?.toString()}
						{...register('title')}
					/>
				</FormGroup>

				<FormGroup>
					<ArticleEditor contentCss="../../../tinymce/content.css" dir="ltr" value={watch('content')} {...register('content')} />
				</FormGroup>

				{apiError && (
					<FormGroup>
						<InfoBox icon="true" type="error" text={apiError || t('error:unexpectedError')} />
					</FormGroup>
				)}

				<FormGroup className="FormGroup_Submit">
					<Button
						text={t('button:save')}
						loading={isLoading}
						type="button"
						onClick={handleSubmit(onSubmit)}
						disabled={isLoading}
					/>
				</FormGroup>
			</Form>

			{process.env.NODE_ENV === 'development' && <DevTool control={control} />}
		</main>
	)
}

export default Admin(AddPage)
