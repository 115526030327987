import { useEffect, useState } from 'react'
import './AddTheory.scss'

// Templates
import Admin from '../../templates/admin/Admin'

// Localization
import { useTranslation } from 'react-i18next'

// React Hook Form
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DevTool } from '@hookform/devtools'

// Atoms
import { Form, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import HistoryBackLink from '../../../ruya-shared/shared/ui/atoms/historyBackLink/HistoryBackLink'
import Switch from '../../../ruya-shared/shared/ui/atoms/switch/Switch'
import Input from '../../../ruya-shared/shared/ui/atoms/input/Input'
import TextArea from '../../../ruya-shared/shared/ui/atoms/textArea/TextArea'

// Molecules
import InfoBox from '../../../ruya-shared/shared/ui/molecules/infoBox/InfoBox'
import TheoryNavigation from '../../molecules/theoryNavigation/TheoryNavigation'

// Zustand
import useInterpreterStore from '../../../store/interpreterStore'

// Router
import { useNavigate } from 'react-router-dom'

// Utils
import { getActiveLanguages } from '../../../ruya-shared/shared/utils/languageHelper'

// Types
import type { IInterpreter } from '../../../ruya-shared/shared/types'

// Validation
import { interpreterSchema } from '../../../validation/interpreter'

// Config
import languageSettings from '../../../ruya-shared/shared/config/languageSettings'

const AddTheory = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Zustand
	const interpreterStore = useInterpreterStore()

	// Router
	const navigate = useNavigate()

	// Get language object
	const languageObject = getActiveLanguages.find(lang => lang.isoCode === languageSettings.defaultLanguage)

	// Input direction (ltr or rtl) depending on the language
	const dir = languageObject?.direction || 'ltr'

	const initialFormValues = {
		language: languageSettings.defaultLanguage,
		name: '',
		description: '',
		focus: '',
		benefit: '',
		prompt: '',
		icon: '',
		active: false,
		menuOrder: 0
	}
	const formOptions = { mode: 'onChange', defaultValues: initialFormValues, resolver: yupResolver(interpreterSchema) } as any
	const {
		register,
		handleSubmit,
		control,
		watch,
		getValues,
		reset,
		setValue,
		formState: { errors, isDirty }
	} = useForm<IInterpreter>(formOptions)

	// Form submit
	const onSubmit = async (values: IInterpreter) => {
		// Update content
		const response = await interpreterStore.addInterpreter(values)

		if (response) {
			navigate('/theories')
		}
	}

	return (
		<main className="AddTheory">
			<h1 className="Admin_H1">{t('admin:headerAddTheory')}</h1>

			<HistoryBackLink />

			<Form className="AddTheory_Form" onSubmit={handleSubmit(onSubmit)} noValidate>
				<FormGroup>
					<Switch label={t('form:isActive.label')} defaultValue={watch('active')} {...register('active')} />
				</FormGroup>

				<FormGroup fourColumn={true}>
					<Input
						inputSize="sm"
						icon="translate"
						type="text"
						placeholder={t('form:language.placeholder')}
						label={t('form:language.label')}
						invalid={Boolean(errors?.language?.message)}
						invalidMessage={errors?.language?.message?.toString()}
						disabled={true}
						{...register('language')}
					/>

					<Input
						inputSize="sm"
						dir={dir}
						type="text"
						placeholder={t('form:title.placeholder')}
						label={t('form:title.label')}
						invalid={Boolean(errors?.name?.message)}
						invalidMessage={errors?.name?.message?.toString()}
						{...register('name')}
					/>

					<Input
						inputSize="sm"
						dir={dir}
						type="text"
						showTooltip={true}
						tooltipText="https://fonts.google.com/icons"
						icon={watch('icon')}
						placeholder={t('form:icon.placeholder')}
						label={t('form:icon.label')}
						invalid={Boolean(errors?.icon?.message)}
						invalidMessage={errors?.icon?.message?.toString()}
						{...register('icon')}
					/>

					<Input
						inputSize="sm"
						type="number"
						placeholder={t('form:menuOrder.placeholder')}
						label={t('form:menuOrder.label')}
						invalid={Boolean(errors?.menuOrder?.message)}
						invalidMessage={errors?.menuOrder?.message?.toString()}
						{...register('menuOrder')}
					/>
				</FormGroup>

				<FormGroup>
					<TextArea
						dir={dir}
						rows={5}
						resize="vertical"
						label={t('form:description.label')}
						invalid={Boolean(errors?.description?.message)}
						invalidMessage={errors?.description?.message?.toString()}
						{...register('description')}
					/>
				</FormGroup>

				<FormGroup>
					<TextArea
						dir={dir}
						rows={5}
						resize="vertical"
						label={t('form:focus.label')}
						invalid={Boolean(errors?.focus?.message)}
						invalidMessage={errors?.focus?.message?.toString()}
						{...register('focus')}
					/>
				</FormGroup>

				<FormGroup>
					<TextArea
						dir={dir}
						rows={5}
						resize="vertical"
						label={t('form:benefit.label')}
						invalid={Boolean(errors?.benefit?.message)}
						invalidMessage={errors?.benefit?.message?.toString()}
						{...register('benefit')}
					/>
				</FormGroup>

				<FormGroup>
					<TextArea
						dir={dir}
						rows={5}
						resize="vertical"
						label={t('form:prompt.label')}
						invalid={Boolean(errors?.prompt?.message)}
						invalidMessage={errors?.prompt?.message?.toString()}
						{...register('prompt')}
					/>
				</FormGroup>

				{interpreterStore.error && (
					<FormGroup>
						<InfoBox icon="true" type="error" text={interpreterStore.error || t('error:unexpectedError')} />
					</FormGroup>
				)}

				<FormGroup className="FormGroup_Submit">
					<Button text={t('button:save')} loading={interpreterStore.loading} type="submit" disabled={interpreterStore.loading} />
				</FormGroup>
			</Form>

			{process.env.NODE_ENV === 'development' && <DevTool control={control} />}
		</main>
	)
}

export default Admin(AddTheory)
