import { useCallback, useEffect, useState } from 'react'
import './AddArticle.scss'

// Templates
import Admin from '../../templates/admin/Admin'

// Localization
import { useTranslation } from 'react-i18next'

// React Hook Form
import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DevTool } from '@hookform/devtools'
import { articleSchema } from '../../../validation/articleSchema'

// Atoms
import { Form, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import Input from '../../../ruya-shared/shared/ui/atoms/input/Input'

// Molecules
import InfoBox from '../../../ruya-shared/shared/ui/molecules/infoBox/InfoBox'
import ArticleEditor from '../../molecules/articleEditor/ArticleEditor'
import { GenerateArticleModal } from '../../molecules/generateArticle/GenerateArticle'

// Zustand
import useArticleStore from '../../../store/articleStore'
import useUserStore from '../../../store/userStore'

// App Settings
import appSettings from '../../../appSettings'

// Router
import { useNavigate } from 'react-router-dom'

// Shared
import languageSettings from '../../../ruya-shared/shared/config/languageSettings'
import type { IArticle, IArticleReference, IArticleStatus, IArticleTag } from '../../../ruya-shared/shared/types'

const AddArticle = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Zustand
	const articleStore = useArticleStore()
	const userStore = useUserStore()

	// Router
	const navigate = useNavigate()

	// API Error State
	const [apiError, setApiError] = useState<string | undefined>(undefined)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isGenerateArticleModalOpen, setGenerateArticleModalOpen] = useState<boolean>(false)
	const [generatedArticle, setGeneratedArticle] = useState<IArticle | null>(null)

	// Get Post Tags
	useEffect(() => {
		articleStore.getArticleTags(languageSettings.defaultLanguage)
	}, [])

	// React Hook Form
	const initialTag: IArticleTag = {
		name: '',
		slug: ''
	}
	const initialReference: IArticleReference = {
		author: '',
		year: '',
		title: '',
		publisherOrJournal: '',
		volume: '',
		issue: '',
		link: ''
	}
	const defaultValues: Partial<IArticle> = {
		lang: languageSettings.defaultLanguage,
		tags: [],
		references: [],
		isFeatured: false,
		isPinned: false,
		readingTime: 0,
		audio: '',
		slug: '',
		title: '',
		description: '',
		content: '',
		status: 'draft' as IArticleStatus,
		authorId: userStore.user?._id
	}
	const formOptions = { defaultValues, mode: 'onChange', resolver: yupResolver(articleSchema) } as any
	const {
		register,
		handleSubmit,
		control,
		watch,
		getValues,
		setValue,
		formState: { errors, isValid }
	} = useForm<IArticle>(formOptions)

	type IGeneratedPostKeys = keyof typeof generatedArticle // Type the keys
	type ValidKeys = keyof IArticle & IGeneratedPostKeys // Intersect with Post keys

	useEffect(() => {
		if (!generatedArticle) return

		Object.keys(generatedArticle).forEach(key => {
			const formKey = key as ValidKeys
			setValue(formKey, generatedArticle[formKey])
		})
	}, [generatedArticle, setValue])

	// Tag field arrays
	const tags = useFieldArray({ name: 'tags', control }) // Tags array
	const watchTagsFieldArray = watch('tags') // Watch tag changes
	const controlledTagFields = tags.fields.map((field, index) => {
		return { ...field, ...(watchTagsFieldArray?.[index] ?? {}) }
	})

	// Reference field arrays
	const references = useFieldArray({ name: 'references', control }) // References array
	const watchReferencesFieldArray = watch('references') // Watch reference changes
	const controlledReferenceFields = references.fields.map((field, index) => {
		return { ...field, ...(watchReferencesFieldArray?.[index] ?? {}) }
	})

	// Watch
	const slugValue = watch('slug')
	const langValue = watch('lang')

	const addNewTag = () => {
		tags.append(initialTag)
	}

	const removeTag = (index: number) => {
		tags.remove(index)
	}

	const addNewReference = () => {
		references.append(initialReference)
	}

	const removeReference = (index: number) => {
		references.remove(index)
	}

	// Handle generated article import
	const handlePostImport = useCallback((article: IArticle) => {
		if (article) {
			setGeneratedArticle(article)
			setGenerateArticleModalOpen(false)
		}
	}, [])

	// Form submit
	const onSubmit = async (values: IArticle) => {
		// Set loading
		setIsLoading(true)

		// Clear API error
		setApiError(undefined)

		// Add article
		const response = await articleStore.addArticle(values)

		if (response.status === 'success') {
			navigate('/article')
		} else {
			setIsLoading(false)
			setApiError(response.message)
		}
	}

	return (
		<main className="AddArticle">
			<h1 className="Admin_H1">{t('admin:headerAddArticle')}</h1>

			<div className="AddArticle_TopBar">
				<Button
					text={t('button:generateArticle')}
					icon="article_shortcut"
					type="button"
					onClick={() => setGenerateArticleModalOpen(true)}
				/>

				{isGenerateArticleModalOpen && (
					<GenerateArticleModal
						isOpen={isGenerateArticleModalOpen}
						onClose={() => setGenerateArticleModalOpen(false)}
						onResponse={handlePostImport}
					/>
				)}
			</div>

			<Form className="AddArticle_Form" noValidate>
				<FormGroup twoColumn={true}>
					<Input
						icon="translate"
						type="text"
						placeholder={t('form:language.placeholder')}
						label={t('form:language.label')}
						invalid={Boolean(errors?.lang?.message)}
						invalidMessage={errors?.lang?.message?.toString()}
						disabled={true}
						{...register('lang')}
					/>

					<Input
						icon="link"
						type="text"
						placeholder={t('form:slug.placeholder')}
						label={t('form:slug.label')}
						invalid={Boolean(errors?.slug?.message)}
						invalidMessage={errors?.slug?.message?.toString()}
						{...register('slug')}
					/>
				</FormGroup>

				<FormGroup>
					<InfoBox icon="true" type="info" text={`http://ruya.co/${langValue}/article/${slugValue}`} />
				</FormGroup>

				<FormGroup>
					<Input
						type="text"
						placeholder={t('form:title.placeholder')}
						label={t('form:title.label')}
						invalid={Boolean(errors?.title?.message)}
						invalidMessage={errors?.title?.message?.toString()}
						{...register('title')}
					/>
				</FormGroup>

				<FormGroup>
					<Input
						type="text"
						placeholder={t('form:description.placeholder')}
						label={t('form:description.label')}
						invalid={Boolean(errors?.description?.message)}
						invalidMessage={errors?.description?.message?.toString()}
						{...register('description')}
					/>
				</FormGroup>

				<FormGroup>
					<ArticleEditor dir="ltr" value={watch('content')} {...register('content')} />
				</FormGroup>

				<FormGroup>
					<Input
						type="number"
						label={t('form:readingTime.label')}
						invalid={Boolean(errors?.readingTime?.message)}
						invalidMessage={errors?.readingTime?.message?.toString()}
						{...register('readingTime')}
					/>
				</FormGroup>

				<FormGroup>
					<h2>Tags</h2>

					{controlledTagFields.map((tag: IArticleTag, i: number) => (
						<FormGroup threeColumn={true} key={`tags${i}`} className="FormGroup_Tags">
							<Input
								inputSize="sm"
								placeholder={t('form:tagName.placeholder')}
								label={t('form:tagName.label')}
								invalid={Boolean(errors?.tags?.[i]?.name?.message)}
								invalidMessage={errors?.tags?.[i]?.name?.message?.toString()}
								{...register(`tags.${i}.name`)}
							/>

							<Input
								inputSize="sm"
								placeholder={t('form:tagSlug.placeholder')}
								label={t('form:tagSlug.label')}
								invalid={Boolean(errors?.tags?.[i]?.slug?.message)}
								invalidMessage={errors?.tags?.[i]?.slug?.message?.toString()}
								{...register(`tags.${i}.slug`)}
							/>

							<Button text={t('button:removeTag')} color="danger" icon="delete" type="button" onClick={() => removeTag(i)} />
						</FormGroup>
					))}

					<Button text={t('button:addNewTag')} icon="add" type="button" onClick={addNewTag} />
				</FormGroup>

				<FormGroup>
					<h2>References</h2>

					{controlledReferenceFields.map((reference: IArticleReference, i: number) => (
						<FormGroup fourColumn={true} key={`references${i}`} className="FormGroup_References">
							<Input
								inputSize="sm"
								placeholder={t('form:referenceTitle.placeholder')}
								label={t('form:referenceTitle.label')}
								invalid={Boolean(errors?.references?.[i]?.title?.message)}
								invalidMessage={errors?.references?.[i]?.title?.message?.toString()}
								{...register(`references.${i}.title`)}
							/>

							<Input
								inputSize="sm"
								placeholder={t('form:referenceLink.placeholder')}
								label={t('form:referenceLink.label')}
								invalid={Boolean(errors?.references?.[i]?.link?.message)}
								invalidMessage={errors?.references?.[i]?.link?.message?.toString()}
								{...register(`references.${i}.link`)}
							/>

							<Input
								inputSize="sm"
								placeholder={t('form:referenceAuthor.placeholder')}
								label={t('form:referenceAuthor.label')}
								{...register(`references.${i}.author`)}
							/>

							<Input
								inputSize="sm"
								placeholder={t('form:referenceYear.placeholder')}
								label={t('form:referenceYear.label')}
								{...register(`references.${i}.year`)}
							/>

							<Input
								inputSize="sm"
								placeholder={t('form:referencePublisherOrJournal.placeholder')}
								label={t('form:referencePublisherOrJournal.label')}
								{...register(`references.${i}.publisherOrJournal`)}
							/>

							<Input
								inputSize="sm"
								placeholder={t('form:referenceVolume.placeholder')}
								label={t('form:referenceVolume.label')}
								{...register(`references.${i}.volume`)}
							/>

							<Input
								inputSize="sm"
								placeholder={t('form:referenceIssue.placeholder')}
								label={t('form:referenceIssue.label')}
								{...register(`references.${i}.issue`)}
							/>

							<Button
								text={t('button:removeReference')}
								color="danger"
								icon="delete"
								type="button"
								onClick={() => removeReference(i)}
							/>
						</FormGroup>
					))}

					<Button text={t('button:addNewReference')} icon="add" type="button" onClick={addNewReference} />
				</FormGroup>

				{apiError && (
					<FormGroup>
						<InfoBox icon="true" type="error" text={apiError || t('error:unexpectedError')} />
					</FormGroup>
				)}

				<FormGroup className="FormGroup_Submit">
					<Button
						text={t('button:addArticleAsDraft')}
						loading={isLoading}
						type="button"
						onClick={handleSubmit(onSubmit)}
						disabled={isLoading}
					/>
				</FormGroup>
			</Form>

			{process.env.NODE_ENV === 'development' && <DevTool control={control} />}
		</main>
	)
}

export default Admin(AddArticle)
