import { useEffect, useState } from 'react'
import './EditArticleSettings.scss'

// Templates
import Admin from '../../templates/admin/Admin'

// Localization
import { useTranslation } from 'react-i18next'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import Loading from '../../../ruya-shared/shared/ui/atoms/loading/Loading'
import HistoryBackLink from '../../../ruya-shared/shared/ui/atoms/historyBackLink/HistoryBackLink'
import { FormFieldSet, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'

// Molecules
import InfoBox from '../../../ruya-shared/shared/ui/molecules/infoBox/InfoBox'
import AdminArticleNavigation from '../../molecules/adminArticleNavigation/AdminArticleNavigation'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// API
import apiProtected from '../../../api/apiProtected'

// Confirmation dialog
import { confirmAlert } from 'react-confirm-alert'

// Toastify
import { toast } from 'react-toastify'

// Shared
import type { IArticle } from '../../../ruya-shared/shared/types'
import { apiErrorHandler } from '../../../ruya-shared/shared/utils/errorHelper'

const EditArticleSettings = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Router
	const navigate = useNavigate()
	const { id } = useParams()

	// API Error State
	const [apiError, setApiError] = useState<string | undefined>(undefined)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [post, setPost] = useState<IArticle | null>(null)

	// Get Post by ID with api call
	useEffect(() => {
		if (!id) navigate('/article')
		getPost()
	}, [id])

	// Get Post by ID
	const getPost = async () => {
		setIsLoading(true)

		try {
			const response = await apiProtected.get(`admin/article/${id}`)

			if (response.data.status === 'success') {
				const articleData = response.data.data as IArticle
				// Update state
				setPost(articleData)
				setIsLoading(false)
			} else {
				setApiError(response.data.message)
				setIsLoading(false)
			}
		} catch (error) {
			setIsLoading(false)
			setApiError(apiErrorHandler(error))
		}
	}

	// Handle status change
	const handleStatus = async (status: string) => {
		// Confirmation dialog
		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:changeStatus', { status }),
			buttons: [
				{
					label: t('button:yes'),
					onClick: async () => {
						setIsLoading(true)

						try {
							const response = await apiProtected.put('admin/article/status', {
								_id: id,
								status
							})

							if (response.data.status === 'success') {
								// Show success message
								toast.success(t('info:translationStatus', { status }), {
									position: 'bottom-center',
									autoClose: 2000
								})

								setIsLoading(false)
							} else {
								// Show error message
								toast.error(response.data.message || t('error:unexpectedError'), {
									position: 'bottom-center',
									autoClose: 2000
								})

								setIsLoading(false)
							}
						} catch (error) {
							setIsLoading(false)
							// Show error message
							toast.error(apiErrorHandler(error), {
								position: 'bottom-center',
								autoClose: 2000
							})
						}
					},
					className: 'button--warning'
				},
				{
					label: t('button:cancel'),
					onClick: () => {}
				}
			]
		})
	}

	// Handle isFeatured change
	const handleIsFeatured = async (isFeatured: boolean) => {
		// Confirmation dialog
		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:changeIsFeatured', { isFeatured }),
			buttons: [
				{
					label: t('button:yes'),
					onClick: async () => {
						setIsLoading(true)

						try {
							const response = await apiProtected.put('admin/article/is-featured', {
								_id: id,
								isFeatured
							})

							if (response.data.status === 'success') {
								// Show success message
								toast.success(t('info:translationIsFeatured', { isFeatured }), {
									position: 'bottom-center',
									autoClose: 2000
								})

								setIsLoading(false)
							} else {
								// Show error message
								toast.error(response.data.message || t('error:unexpectedError'), {
									position: 'bottom-center',
									autoClose: 2000
								})

								setIsLoading(false)
							}
						} catch (error) {
							setIsLoading(false)
							// Show error message
							toast.error(apiErrorHandler(error), {
								position: 'bottom-center',
								autoClose: 2000
							})
						}
					},
					className: 'button--warning'
				},
				{
					label: t('button:cancel'),
					onClick: () => {}
				}
			]
		})
	}

	// Handle isPinned change
	const handleIsPinned = async (isPinned: boolean) => {
		// Confirmation dialog
		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:changeIsPinned', { isPinned }),
			buttons: [
				{
					label: t('button:yes'),
					onClick: async () => {
						setIsLoading(true)

						try {
							const response = await apiProtected.put('admin/article/is-pinned', {
								_id: id,
								isPinned
							})

							if (response.data.status === 'success') {
								// Show success message
								toast.success(t('info:translationIsPinned', { isPinned }), {
									position: 'bottom-center',
									autoClose: 2000
								})

								setIsLoading(false)
							} else {
								// Show error message
								toast.error(response.data.message || t('error:unexpectedError'), {
									position: 'bottom-center',
									autoClose: 2000
								})

								setIsLoading(false)
							}
						} catch (error) {
							setIsLoading(false)
							// Show error message
							toast.error(apiErrorHandler(error), {
								position: 'bottom-center',
								autoClose: 2000
							})
						}
					},
					className: 'button--warning'
				},
				{
					label: t('button:cancel'),
					onClick: () => {}
				}
			]
		})
	}

	// Handle delete click
	const handleDelete = async () => {
		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:deleteArticle'),
			buttons: [
				{
					label: t('button:yes'),
					onClick: () => deleteArticle(),
					className: 'button--danger'
				},
				{
					label: t('button:cancel'),
					onClick: () => {
						return false
					}
				}
			]
		})
	}

	// Delete post
	const deleteArticle = async () => {
		try {
			const response = await apiProtected.delete(`admin/article/${id}`)

			if (response?.data?.status === 'success') {
				// Redirect to post list
				navigate('/article')
			} else {
				// Show error message
				toast.error(response.data.message || t('error:unexpectedError'), {
					position: 'bottom-center',
					autoClose: 2000
				})
			}
		} catch (error) {
			// Show error message
			toast.error(apiErrorHandler(error) || t('error:unexpectedError'), {
				position: 'bottom-center',
				autoClose: 2000
			})
		}
	}

	const handleIndexNow = async () => {
		// Confirmation dialog
		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:indexNow'),
			buttons: [
				{
					label: t('button:yes'),
					onClick: async () => {
						setIsLoading(true)

						try {
							const response = await apiProtected.post('admin/article/index-now', {
								_id: id
							})

							if (response.data.status === 'success') {
								// Show success message
								toast.success(t('info:translationIndexNow'), {
									position: 'bottom-center',
									autoClose: 2000
								})

								setIsLoading(false)
							} else {
								// Show error message
								toast.error(response.data.message || t('error:unexpectedError'), {
									position: 'bottom-center',
									autoClose: 2000
								})

								setIsLoading(false)
							}
						} catch (error) {
							setIsLoading(false)
							// Show error message
							toast.error(apiErrorHandler(error), {
								position: 'bottom-center',
								autoClose: 2000
							})
						}
					},
					className: 'button--warning'
				},
				{
					label: t('button:cancel'),
					onClick: () => {}
				}
			]
		})
	}

	// Loading
	if (isLoading)
		return (
			<div className="EditArticleSettings">
				<Loading />
			</div>
		)

	return (
		<main className="EditArticleSettings">
			<h1 className="Admin_H1">{t('admin:headerEditArticle')}</h1>

			<HistoryBackLink />

			<InfoBox icon="true" type="info" text={`http://ruya.co/${post?.lang}/articles/${post?.slug}`} />

			<AdminArticleNavigation id={id} lang={post?.lang} />

			{apiError && <InfoBox icon="true" type="error" text={apiError || t('error:unexpectedError')} />}

			<div className="EditArticleSettings_Settings">
				<FormFieldSet className="EditArticleSettings_SEO" legend={t('admin:seoHeader')}>
					<p>{t('admin:seoIndexNowDesc')}</p>
					<FormGroup>
						<Button text={t('button:indexNow')} color="danger" onClick={handleIndexNow} />
					</FormGroup>
				</FormFieldSet>

				<FormFieldSet className="EditArticleSettings_BulkOperations" legend={t('admin:bulkHeader')}>
					<p>
						<i>{t('admin:bulkDesc')}</i>
					</p>
					<p>{t('admin:articleStatus')}</p>
					<FormGroup threeColumn={true}>
						<Button text={t('button:publishAll')} disabled={isLoading} onClick={() => handleStatus('published')} />
						<Button
							text={t('button:unPublishAll')}
							color="secondary"
							disabled={isLoading}
							onClick={() => handleStatus('draft')}
						/>
						<Button
							text={t('button:archiveAll')}
							color="danger"
							disabled={isLoading}
							onClick={() => handleStatus('archived')}
						/>
					</FormGroup>
					<p>{t('admin:isFeatured')}</p>
					<FormGroup threeColumn={true}>
						<Button text={t('button:featureAll')} disabled={isLoading} onClick={() => handleIsFeatured(true)} />
						<Button
							text={t('button:unFeatureAll')}
							color="secondary"
							disabled={isLoading}
							onClick={() => handleIsFeatured(false)}
						/>
					</FormGroup>
					<p>{t('admin:isPinned')}</p>
					<FormGroup threeColumn={true}>
						<Button text={t('button:pinAll')} disabled={isLoading} onClick={() => handleIsPinned(true)} />
						<Button text={t('button:unPinAll')} color="secondary" disabled={isLoading} onClick={() => handleIsPinned(false)} />
					</FormGroup>
				</FormFieldSet>
				<FormFieldSet className="EditArticleSettings_DangerZone" legend={t('admin:dangerZone')}>
					<p>{t('admin:deleteAllArticleDesc')}</p>
					<Button text={t('button:deleteAll')} color="danger" disabled={isLoading} onClick={handleDelete} />
				</FormFieldSet>
			</div>
		</main>
	)
}

export default Admin(EditArticleSettings)
