import type { HTMLAttributes, ReactNode } from 'react'
import './InfoBox.scss'

// Atoms
import MaterialSymbol from '../../atoms/materialSymbol/MaterialSymbol'
import P from '../../atoms/p/P'

interface InfoBoxProps extends HTMLAttributes<HTMLDivElement> {
	header?: string
	text?: string
	icon?: string
	className?: string
	children?: ReactNode
	type?: 'info' | 'success' | 'warning' | 'error' | 'highlight'
}

const InfoBox = (props: InfoBoxProps) => {
	const { icon, header, text, className, ...rest } = props

	// Classes
	const classNames = 'InfoBox' + `${className !== undefined ? ' ' + className : ''}`

	return (
		<div className={classNames} {...rest}>
			{header && (
				<P size="md-bold" className="InfoBox_Header">
					{icon && <MaterialSymbol name={icon} fill="1" />} {header}
				</P>
			)}
			{text && <P size="sm">{text}</P>}
			{props.children}
		</div>
	)
}

export default InfoBox
