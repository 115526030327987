import type React from 'react'
import { useState, forwardRef, type ForwardRefRenderFunction, useEffect } from 'react'
import './Switch.scss'

// Atoms
import Tooltip from '../tooltip/Tooltip'
import MaterialSymbol from '../materialSymbol/MaterialSymbol'

// Utils
import { generateRandomLetters } from '../../../utils/commonHelper'

interface CustomValues {
	on: {
		value: any
		label: string
	}
	off: {
		value: any
		label: string
	}
}

interface SwitchProps {
	icon?: string
	defaultValue?: any
	value?: any
	values?: CustomValues
	name?: string
	label?: string
	labelPosition?: 'left' | 'right'
	className?: string
	showTooltip?: boolean
	tooltipText?: string
	inputSize?: string
	showValueLabel?: boolean
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Switch: ForwardRefRenderFunction<HTMLInputElement, SwitchProps> = (props, ref) => {
	// Props
	const {
		icon,
		defaultValue,
		value,
		values,
		name = generateRandomLetters(),
		label,
		labelPosition = 'right',
		className,
		inputSize,
		showTooltip = false,
		tooltipText = '',
		onChange
	} = props

	// Determine if the component is controlled
	const isControlled = value !== undefined

	// Default values
	const defaultValues = {
		on: { value: true, label: 'On' },
		off: { value: false, label: 'Off' }
	}
	const actualValues = values || defaultValues

	// State
	const [isChecked, setChecked] = useState(defaultValue === actualValues.on.value)
	const [currentValue, setCurrentValue] = useState(isChecked ? actualValues.on.value : actualValues.off.value)

	// Update state when value prop changes (for controlled component)
	useEffect(() => {
		if (isControlled) {
			setChecked(value === actualValues.on.value)
		}
	}, [value, actualValues])

	// Classes
	const classNames =
		'Switch' +
		(className ? ' ' + className : '') +
		(inputSize ? ' Switch--' + inputSize : '') +
		(isChecked ? ' Switch--checked' : '') +
		(label ? ' Switch--hasLabel' : '')

	useEffect(() => {
		if (defaultValue === actualValues.on.value) {
			setChecked(true)
		} else if (defaultValue === actualValues.off.value) {
			setChecked(false)
		}
	}, [defaultValue, actualValues])

	// Handler
	const handleToggle = () => {
		const newValue = isControlled ? !value : !isChecked

		if (!isControlled) {
			// Update state for uncontrolled component
			setChecked(newValue)
		}

		// Call onChange for both controlled and uncontrolled
		onChange?.({
			target: { name, value: newValue ? actualValues.on.value : actualValues.off.value }
		} as React.ChangeEvent<HTMLInputElement>)
	}

	return (
		<div className={classNames}>
			{(label || icon || showTooltip) && (
				<div className="Switch_Label">
					{icon && <MaterialSymbol name={icon} fill="1" />}
					{label && <label htmlFor={name}>{label}</label>}
					{showTooltip && (
						<Tooltip content={tooltipText} disabled={tooltipText.length <= 0}>
							<MaterialSymbol className="Switch_Label_Help" name="help" fill="1" />
						</Tooltip>
					)}
				</div>
			)}
			<div className="Switch_Wrapper">
				{labelPosition === 'left' && (
					<span className="Switch_Wrapper_Label">{isChecked ? actualValues.on.label : actualValues.off.label}</span>
				)}
				<div className="Switch_Slider" onClick={handleToggle}>
					<span className="Switch_Slider_Slide" />
				</div>
				<input type="hidden" value={isChecked ? actualValues.on.value : actualValues.off.value} name={name} ref={ref} readOnly />
				{labelPosition === 'right' && (
					<span className="Switch_Wrapper_Label">{isChecked ? actualValues.on.label : actualValues.off.label}</span>
				)}
			</div>
		</div>
	)
}

Switch.displayName = 'Switch'
export default forwardRef(Switch)
