import './P.scss'

interface IPProps {
	children: React.ReactNode | React.ReactNode[]
	className?: string
	size?: 'sm' | 'md' | 'md-bold' | 'lg' | 'xl'
}

const P = (props: IPProps) => {
	// Props
	const { children, className, size = 'md' } = props

	// Class names
	const classNames = 'P' + ` P--${size}` + `${className ? ' ' + className : ''}`

	return <p className={classNames}>{children}</p>
}

export default P
