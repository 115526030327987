// Zustand
import { create } from 'zustand'

// API
import apiProtected from '../api/apiProtected'

// Utils
import { errorHandler } from '../ruya-shared/shared/utils/errorHelper'

// Types
import type { PlanDataState, PlanState } from '../@types/plan'
import { IPlan } from '../ruya-shared/shared/types'

const initialState: PlanDataState = {
	planList: [],
	loading: false,
	error: null
}

const usePlanStore = create<PlanState>((set, get) => ({
	...initialState,
	setLoading: loading => set({ loading }),
	setApiError: error => set({ error }),
	loadPlanList: async active => {
		// Set loading
		set({ loading: true, error: null })

		try {
			const { data, status } = await apiProtected.get('/admin/plans' + (active === 'all' ? '' : '?active=' + Boolean(active)))

			// Check axios error
			if (status !== 200) set({ error: 'Error fetching plan list', loading: false })

			// Check API error
			if (data.status !== 'success') set({ error: data.message, loading: false })

			console.log(data)

			set({ planList: data.data.planList, loading: false })
		} catch (error) {
			set({ error: errorHandler(error), loading: false })
		}
	},
	addPlan: async planData => {
		// Set loading
		set({ loading: true, error: null })

		try {
			const { status } = await apiProtected.post('/admin/plans', planData)

			// Check axios error
			if (status !== 201) set({ error: 'Error adding plan', loading: false })

			set({ loading: false })
			return true
		} catch (error) {
			set({ error: errorHandler(error), loading: false })
			return false
		}
	},
	updatePlan: async planData => {
		// Set loading
		set({ loading: true, error: null })

		try {
			const { data } = await apiProtected.put('/admin/plans', planData)

			// Check axios error
			if (data.status !== 'success') set({ error: data.message, loading: false })

			set({ loading: false })

			return data
		} catch (error) {
			set({ error: errorHandler(error), loading: false })
			return false
		}
	}

}))

export default usePlanStore
