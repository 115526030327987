import * as Yup from 'yup'

// Localisation
import i18n from '../i18n'

// Types
import type { IPlan } from '../ruya-shared/shared/types'

// Helper: dynamic schema for currency values for one billing cycle (e.g., monthly or yearly)
export const createCurrencyValuesSchema = Yup.lazy(values => {
	if (values === null || values === undefined) {
		return Yup.object().required(i18n.t('validation:plan.currencyValuesRequired'))
	}
	const shape: Record<string, Yup.NumberSchema<number | undefined>> = {}
	Object.keys(values).forEach(currencyCode => {
		shape[currencyCode] = Yup.number()
			.transform((value, originalValue) => {
				// Transform string values to numbers (if possible)
				if (typeof originalValue === 'string') {
					const parsed = Number.parseFloat(originalValue)
					return Number.isNaN(parsed) ? value : parsed
				}
				return value
			})
			.required(i18n.t('validation:plan.currencyValueRequired', { currencyCode }))
	})
	return Yup.object().shape(shape)
})

// Helper: promotion schema (an array of promotion objects)
export const promotionSchema = Yup.object().shape({
	code: Yup.string().required(i18n.t('validation:plan.promotionCodeRequired')),
	discountPercent: Yup.number().required(i18n.t('validation:plan.promotionDiscountPercentRequired')),
	active: Yup.boolean().required(i18n.t('validation:plan.promotionActiveRequired')),
	validFrom: Yup.date().required(i18n.t('validation:plan.promotionValidFromRequired')),
	validTo: Yup.date().required(i18n.t('validation:plan.promotionValidToRequired')),
	description: Yup.string().required(i18n.t('validation:plan.promotionDescriptionRequired'))
})

// POST: Add plan schema
export const addPlanSchema: Yup.ObjectSchema<Yup.AnyObject, Partial<IPlan>> = Yup.object({
	name: Yup.string().required(i18n.t('validation:plan.nameRequired')),
	displayName: Yup.string().required(i18n.t('validation:plan.displayNameRequired')),
	description: Yup.string().required(i18n.t('validation:plan.descriptionRequired')),
	features: Yup.array().of(Yup.string()).required(i18n.t('validation:plan.featuresRequired')),
	trialDuration: Yup.number().notRequired(),
	order: Yup.number().required(i18n.t('validation:plan.orderRequired')),
	active: Yup.boolean().required(i18n.t('validation:plan.activeRequired')),
	hidden: Yup.boolean().required(i18n.t('validation:plan.hiddenRequired')),
	dreamJournal: Yup.number().notRequired(),
	dailyJournal: Yup.number().notRequired(),
	eventJournal: Yup.number().notRequired(),
	dreamInterpretation: Yup.number().notRequired(),
	dreamImage: Yup.number().notRequired(),
	dreamWeeklyAnalysis: Yup.number().notRequired(),
	dreamMonthlyAnalysis: Yup.number().notRequired(),
	// Define price as an object with "monthly" and "yearly" keys,
	// each using our lazy schema to validate dynamic currency keys.
	price: Yup.object({
		monthly: createCurrencyValuesSchema,
		yearly: createCurrencyValuesSchema
	}).required(i18n.t('validation:plan.priceRequired')),
	billingCycle: Yup.array().of(Yup.string()).required(i18n.t('validation:plan.billingCycleRequired')),
	promotions: promotionSchema
})

// PUT: Update plan schema
export const updatePlanSchema: Yup.ObjectSchema<Yup.AnyObject, Partial<IPlan>> = Yup.object({
	_id: Yup.string().required(i18n.t('validation:plan.idRequired')),
	name: Yup.string().required(i18n.t('validation:plan.nameRequired')),
	displayName: Yup.string().required(i18n.t('validation:plan.displayNameRequired')),
	description: Yup.string().required(i18n.t('validation:plan.descriptionRequired')),
	features: Yup.array().of(Yup.string()).required(i18n.t('validation:plan.featuresRequired')),
	trialDuration: Yup.number().notRequired(),
	order: Yup.number().required(i18n.t('validation:plan.orderRequired')),
	active: Yup.boolean().required(i18n.t('validation:plan.activeRequired')),
	hidden: Yup.boolean().required(i18n.t('validation:plan.hiddenRequired')),
	dreamJournal: Yup.number().notRequired(),
	dailyJournal: Yup.number().notRequired(),
	eventJournal: Yup.number().notRequired(),
	dreamInterpretation: Yup.number().notRequired(),
	dreamImage: Yup.number().notRequired(),
	dreamWeeklyAnalysis: Yup.number().notRequired(),
	dreamMonthlyAnalysis: Yup.number().notRequired(),
	// Define price as an object with "monthly" and "yearly" keys,
	// each using our lazy schema to validate dynamic currency keys.
	price: Yup.object({
		monthly: createCurrencyValuesSchema,
		yearly: createCurrencyValuesSchema
	}).required(i18n.t('validation:plan.priceRequired')),
	billingCycle: Yup.array().of(Yup.string()).required(i18n.t('validation:plan.billingCycleRequired')),
	promotions: promotionSchema
})
