import type { ButtonHTMLAttributes } from 'react'
import './Button.scss'

import MaterialSymbol from '../materialSymbol/MaterialSymbol'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	loading?: boolean
	loadingText?: string
	className?: string
	text?: string
	icon?: string
	iconFill?: '0' | '1'
	iconPosition?: 'left' | 'right'
	size?: 'lg' | 'md' | 'sm' | 'xs'
	color?: 'primary' | 'secondary' | 'tertiary' | 'danger'
	block?: boolean
	active?: boolean
	disabled?: boolean
	disableOnLoading?: boolean
	[key: string]: any
}

const Button = (props: ButtonProps) => {
	const {
		loading,
		text,
		loadingText,
		className,
		icon,
		iconFill = '0',
		iconPosition = 'left',
		size = 'md',
		color = 'primary',
		block,
		selected = false,
		disabled,
		disableOnLoading = false,
		type = 'button',
		...rest
	} = props

	// Classes
	const classNames =
		'Button' +
		`${loading ? ' Button--loading' : ''}` +
		`${block ? ' Button--block' : ''}` +
		`${selected ? ' Button--selected' : ''}` +
		` Button--${size}` +
		`${className !== undefined ? ' ' + className : ''}`

	return (
		<button
			color={color}
			aria-label={loading ? loadingText || text : text}
			className={classNames}
			disabled={disableOnLoading ? disabled || loading : disabled}
			type={type}
			{...rest}>
			{loading && iconPosition === 'left' && <MaterialSymbol name="progress_activity" className="css-spin" />}
			{!loading && iconPosition === 'left' && icon && <MaterialSymbol name={icon} fill={iconFill} />}
			{loading ? loadingText || text : text}
			{!loading && iconPosition === 'right' && icon && <MaterialSymbol name={icon} fill={iconFill} />}
			{loading && iconPosition === 'right' && <MaterialSymbol name="progress_activity" className="css-spin" />}
		</button>
	)
}

export default Button
