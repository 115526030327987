import { useState, useEffect } from 'react'
import './UserChartByLanguage.scss'

// API
import apiProtected from '../../../api/apiProtected'

// Chart
import { type BarDatum, ResponsiveBar } from '@nivo/bar'

// Atoms
import Loading from '../../../ruya-shared/shared/ui/atoms/loading/Loading'

// Custom hook for window size
import { useWindowSize } from '../../../hooks/useWindowSize'

const customTheme = {
	axis: {
		ticks: {
			text: {
				fontFamily: 'Figtree, sans-serif',
				fontSize: 12,
			},
		},
		legend: {
			text: {
				fontFamily: 'Figtree, sans-serif',
				fontSize: 12,
			},
		},
	},
	labels: {
		text: {
			fontFamily: 'Figtree, sans-serif',
		},
	},
}

const UserChartByLanguage = () => {
	const { width } = useWindowSize()
	const [loading, setLoading] = useState(true)
	const [chartData, setChartData] = useState<BarDatum[] | null>(null)

	useEffect(() => {
		fetchData()
	}, [])

	// Fetch data and sort it in ascending order.
	// With a horizontal layout, Nivo renders the first data item at the bottom,
	// so ascending order places the highest count at the top.
	const fetchData = async () => {
		try {
			setLoading(true)
			const response = await apiProtected.get('/admin/statistics/users-by-language')
			const data: BarDatum[] = response.data.data

			// Sort in ascending order so that the highest (last item) appears at the top.
			data.sort((a, b) => Number(a.count || 0) - Number(b.count || 0))
			setChartData(data)
			setLoading(false)
		} catch (error) {
			setChartData([])
			console.error('Error fetching chart data:', error)
			setLoading(false)
		}
	}

	// Generate tick values for the x-axis (Subscribers) based on max count and available width.
	// When the width is small, we use fewer tick values.
	const generateTickValues = (data: BarDatum[], width: number) => {
		const maxVal = Math.max(
			...data.map(item => (typeof item.count === 'number' ? item.count : 0))
		)
		const desiredTickCount = width < 500 ? 3 : 10
		const step = Math.ceil(maxVal / desiredTickCount)
		const tickCount = Math.floor(maxVal / step) + 1
		return Array.from({ length: tickCount }, (_, index) => index * step)
	}

	// Set container height dynamically based on the number of languages.
	const containerHeight = chartData ? chartData.length * 40 + 100 : 300

	return (
		<div className="UserChartByLanguage">
			<h4 className="UserChartByLanguage_Header">Users by language</h4>
			<div className="UserChartByLanguage_Body">
				{loading && <Loading />}
				{!loading && chartData && (
					<div
						className="UserChartByLanguage_Body_Chart"
						style={{ height: containerHeight }}
					>
						{/* Using key={width} forces re-render on window resize */}
						<ResponsiveBar
							key={width}
							data={chartData}
							theme={customTheme}
							layout="horizontal" // horizontal bar chart
							keys={['count']}
							indexBy="language"
							margin={{ top: 0, right: 10, bottom: 50, left: 200 }}
							padding={0.3}
							valueScale={{ type: 'linear' }}
							indexScale={{ type: 'band', round: true }}
							colors={{ scheme: 'set3' }}
							borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
							axisTop={null}
							axisRight={null}
							// x-axis: shows subscriber counts.
							axisBottom={{
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								legend: 'User count',
								legendPosition: 'middle',
								legendOffset: 40,
								tickValues: generateTickValues(chartData, width),
							}}
							// y-axis: shows languages.
							axisLeft={{
								tickSize: 0,
								tickPadding: 5,
								tickRotation: 0,
								legend: 'Language',
								legendPosition: 'middle',
								// Adjust the offset to position the legend vertically
								legendOffset: -180,
							}}
							labelSkipWidth={12}
							labelSkipHeight={12}
							labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
							legends={[]}
							animate={true}
						/>
					</div>
				)}
			</div>
		</div>
	)
}

export default UserChartByLanguage
