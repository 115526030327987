import type React from 'react'
import { useState, useCallback } from 'react'
import './ImageCropper.scss'

// React Easy Crop
import Cropper from 'react-easy-crop'
import type { Area } from 'react-easy-crop/types'

// App Settings
import appSettings from '../../../appSettings'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import { FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'

// Molecules
import InfoBox from '../../../ruya-shared/shared/ui/molecules/infoBox/InfoBox'
import FileInput from '../fileInput/FileInput'

// Localization
import { useTranslation } from 'react-i18next'

// API
import apiProtected from '../../../api/apiProtected'

// Shared
import { apiErrorHandler, errorHandler } from '../../../ruya-shared/shared/utils/errorHelper'

interface ICrop {
	x: number
	y: number
}

// Interface for the state of the image cropper
interface ImageCropperState {
	id: string
	displayName: string
	crop: ICrop
	zoom: number
	aspect: number
	rotation: number
	targetHeight: number
	targetWidth: number
	croppedAreaPixels?: Area
}

interface ImageCropperProps {
	savePath: string
	minHeight?: number
	minWidth?: number
	onFinish: () => void
}

const ImageCropper = (props: ImageCropperProps) => {
	// Props
	const { savePath, onFinish, minWidth, minHeight } = props

	// State
	const [imageSrc, setImageSrc] = useState<string | null>(null) // Image source in base64 format
	const [imageCropperState, setImageCropperState] = useState<ImageCropperState[] | []>([]) // State of the image cropper
	const [originalImageSize, setOriginalImageSize] = useState<{ width: number; height: number } | null>(null) // Original image size
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [error, setError] = useState<string | null>(null)

	// Translation Hook
	const { t } = useTranslation()

	// Read file and return it in base64 format
	const readFile = (file: File): Promise<string> =>
		new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.onload = () => resolve(reader.result as string)
			reader.onerror = reject
			reader.readAsDataURL(file)
		})

	const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files.length > 0) {
			// Get the file
			const file = e.target.files[0]

			// Read the file
			const imageDataUrl = await readFile(file)

			// Set the image source
			setImageSrc(imageDataUrl)

			// Create a new image to load the selected file
			const img = new Image()
			img.onload = () => {
				// Check if the image meets the minimum size requirements
				if (minWidth && minHeight && (img.naturalWidth < minWidth || img.naturalHeight < minHeight)) {
					// Set an error message if it doesn't meet the requirements
					setError(t('error:postImageIsTooSmall', { minWidth, minHeight }))
					return
				}
				setError(null)

				// Set the original image size
				setOriginalImageSize({ width: img.naturalWidth, height: img.naturalHeight })

				// Initialize cropper state for each image size
				const initialState: ImageCropperState[] = appSettings.admin.post.imageSizes.map(settings => ({
					id: settings.name,
					displayName: settings.displayName,
					crop: { x: 0, y: 0 },
					zoom: 1,
					rotation: 0,
					aspect: settings.width / settings.height,
					targetHeight: settings.height,
					targetWidth: settings.width
				}))

				setImageCropperState(initialState)
			}
			img.onerror = error => {
				setError(errorHandler(error))
			}
			img.src = imageDataUrl
		}
	}

	const onCropChange = useCallback((id: string, crop: ICrop) => {
		setImageCropperState(currentState =>
			currentState.map(item => {
				if (item.id === id) {
					return { ...item, crop }
				}
				return item
			})
		)
	}, [])

	const onZoomChange = useCallback((id: string, zoom: number) => {
		setImageCropperState(currentState =>
			currentState.map(item => {
				if (item.id === id) {
					return { ...item, zoom }
				}
				return item
			})
		)
	}, [])

	const onRotationChange = useCallback((id: string, rotation: number) => {
		setImageCropperState(currentState =>
			currentState.map(item => {
				if (item.id === id) {
					return { ...item, rotation }
				}
				return item
			})
		)
	}, [])

	const onCropComplete = useCallback(
		(id: string, croppedArea: Area, croppedAreaPixels: Area) => {
			setImageCropperState(currentState =>
				currentState.map(item => {
					if (item.id === id) {
						return { ...item, croppedAreaPixels }
					}
					return item
				})
			)
		},
		[originalImageSize]
	)

	const submitToBackend = async () => {
		// Set loading state
		setIsLoading(true)

		// Check if image source is available
		if (!imageSrc) {
			setError(t('error:noImageSelected'))
			return
		}

		// Create FormData
		const formData = new FormData()
		const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement

		// Append the file to FormData, if a file was selected
		if (fileInput?.files?.[0]) {
			formData.append('image', fileInput.files[0])
		} else {
			setError(t('error:noImageSelected'))
			return
		}

		// Append the image path to FormData
		formData.append('savePath', savePath)
		formData.append('originalImageSize', JSON.stringify(originalImageSize))
		formData.append('cropInfo', JSON.stringify(imageCropperState))

		// Send the FormData to the backend
		try {
			const response = await apiProtected.post('admin/image/post-image-cropper', formData, {
				headers: { 'Content-Type': 'multipart/form-data' }
			})
			if (response.data.status === 'success') {
				setError(null)
				setIsLoading(false)
				onFinish()
			} else {
				setIsLoading(false)
				setError(response.data.message)
			}
		} catch (error) {
			setIsLoading(false)
			setError(apiErrorHandler(error))
		}
	}

	return (
		<div className="ImageCropper">
			<div className="ImageCropper_SelectFile">
				<FileInput accept="img/*" name="file" label={t('form:selectImageForArticle.label')} onChange={onFileChange} />
			</div>
			{imageSrc && (
				<div className="ImageCropper_Preview">
					{imageCropperState.map((imageState: ImageCropperState) => {
						return (
							<div key={imageState.id} className="ImageCropper_EditorContainer">
								<h3>
									{imageState.displayName} {`${imageState.targetWidth}x${imageState.targetHeight}px`}
								</h3>
								<div
									key={imageState.id}
									className="ImageCropper_EditorArea"
									style={{ height: imageState.targetHeight, minWidth: imageState.targetWidth }}>
									<Cropper
										image={imageSrc}
										crop={imageState.crop}
										zoom={imageState.zoom}
										aspect={imageState.aspect}
										rotation={imageState.rotation}
										showGrid={true}
										maxZoom={3}
										zoomSpeed={1}
										zoomWithScroll={true}
										onCropChange={crop => onCropChange(imageState.id, crop)}
										onZoomChange={zoom => onZoomChange(imageState.id, zoom)}
										onRotationChange={rotation => onRotationChange(imageState.id, rotation)}
										onCropComplete={(croppedArea, croppedAreaPixels) =>
											onCropComplete(imageState.id, croppedArea, croppedAreaPixels)
										}
									/>
								</div>
								<div className="ImageCropper_EditorTools">
									<label>
										{t('admin:zoom')}
										<input
											type="range"
											value={imageState.zoom}
											min={1}
											max={3}
											step={0.1}
											onChange={e => onZoomChange(imageState.id, +e.target.value)}
										/>
									</label>
									<label>
										{t('admin:rotation')}
										<input
											type="range"
											value={imageState.rotation}
											min={0}
											max={360}
											step={1}
											onChange={e => onRotationChange(imageState.id, +e.target.value)}
										/>
									</label>
								</div>
							</div>
						)
					})}

					{error && (
						<FormGroup>
							<InfoBox icon="true" type="error" text={error || t('error:unexpectedError')} />
						</FormGroup>
					)}

					{!error && (
						<div className="ImageCropper_Buttons">
							<Button text={t('button:addImages')} loading={isLoading} disabled={isLoading} onClick={submitToBackend} />
						</div>
					)}
				</div>
			)}
		</div>
	)
}

export default ImageCropper
