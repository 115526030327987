import { useEffect, useState } from 'react'
import './EditArticleTranslations.scss'

// Templates
import Admin from '../../templates/admin/Admin'

// Localization
import { useTranslation } from 'react-i18next'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import Select from '../../../ruya-shared/shared/ui/atoms/select/Select'
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'
import Tooltip from '../../../ruya-shared/shared/ui/atoms/tooltip/Tooltip'
import ReactLink from '../../../ruya-shared/shared/ui/molecules/reactLink/ReactLink'
import HistoryBackLink from '../../../ruya-shared/shared/ui/atoms/historyBackLink/HistoryBackLink'
import ProgressBar from '../../../ruya-shared/shared/ui/atoms/progressBar/ProgressBar'
import { FormFieldSet, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'

// Molecules
import InfoBox from '../../../ruya-shared/shared/ui/molecules/infoBox/InfoBox'
import AdminArticleNavigation from '../../molecules/adminArticleNavigation/AdminArticleNavigation'

// Zustand
import useLanguageStore from '../../../store/languageStore'

// App Settings
import appSettings from '../../../appSettings'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// API
import apiProtected from '../../../api/apiProtected'

// Confirmation dialog
import { confirmAlert } from 'react-confirm-alert'
import { toast } from 'react-toastify'

// Shared
import type { IArticle, IArticleLanguageRelation, ILanguage } from '../../../ruya-shared/shared/types'
import languageSettings from '../../../ruya-shared/shared/config/languageSettings'
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'
import { apiErrorHandler } from '../../../ruya-shared/shared/utils/errorHelper'

const EditArticleTranslations = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Zustand
	const languageStore = useLanguageStore()

	// Router
	const navigate = useNavigate()
	const { id } = useParams()

	// API Error State
	const [apiError, setApiError] = useState<string | undefined>(undefined)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [article, setPost] = useState<IArticle | null>(null)
	const [translationButtonsDisable, setTranslationButtonsDisable] = useState<boolean>(false)
	const [isTranslateAllLoading, setIsTranslateAllLoading] = useState<boolean>(false)

	// Translation status
	const [bulkOperation, setBulkOperation] = useState<IBulkAIOperation | null>(null)
	const [bulkOperationAudio, setBulkOperationAudio] = useState<IBulkAIOperation | null>(null)

	useEffect(() => {
		checkBulkOperationStatus('translation')
		checkBulkOperationStatus('audio')
	}, [])

	useEffect(() => {
		const interval = setInterval(() => {
			if (bulkOperation?.status === 'in-progress') {
				checkBulkOperationStatus('translation')
				checkBulkOperationStatus('audio')
				getPost()
			}
		}, 20000)
		return () => clearInterval(interval)
	}, [bulkOperation])

	const checkBulkOperationStatus = async (operationType: string) => {
		setIsTranslateAllLoading(true)
		try {
			const { data } = await apiProtected.get(`admin/ai/bulk-operation-status/${operationType}`)
			if (data?.status === 'success' && data?.data) {
				if (operationType === 'translation') setBulkOperation(data.data.operation)
				if (operationType === 'audio') setBulkOperationAudio(data.data.operation)
			} else {
				if (operationType === 'translation') setBulkOperation(null)
				if (operationType === 'audio') setBulkOperationAudio(null)
			}
		} catch (error) {
			setApiError(apiErrorHandler(error))
		} finally {
			setIsTranslateAllLoading(false)
		}
	}

	const stopBulkOperation = async (operationType: string, forceStop: boolean = false) => {
		try {
			const { data } = await apiProtected.get(`admin/ai/stop-bulk-operation/${operationType}/${forceStop}`)
			if (data?.status === 'success' && data?.data) {
				if (operationType === 'translation') setBulkOperation(data.data.operation)
				if (operationType === 'audio') setBulkOperationAudio(data.data.operation)
			} else {
				if (operationType === 'translation') setBulkOperation(null)
				if (operationType === 'audio') setBulkOperationAudio(null)
			}
		} catch (error) {
			setApiError(apiErrorHandler(error))
		}
	}

	// Get Post by ID with api call
	useEffect(() => {
		if (!id) navigate('/article')
		getPost()
	}, [id])

	// Get Post by ID
	const getPost = async () => {
		setIsLoading(true)

		try {
			const response = await apiProtected.get(`admin/article/${id}`)

			if (response.data.status === 'success') {
				const articleData = response.data.data as IArticle
				// Update state
				setPost(articleData)
				setIsLoading(false)
			} else {
				setApiError(response.data.message)
				setIsLoading(false)
			}
		} catch (error) {
			setIsLoading(false)
			setApiError(apiErrorHandler(error))
		}
	}

	// Handle translation click
	const handleTranslateAll = async () => {
		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:translateAll'),
			buttons: [
				{
					label: t('button:translateAll'),
					onClick: () => translateAll(true),
					className: 'button--warning'
				},
				{
					label: t('button:translateOnlyMissing'),
					onClick: () => translateAll(false),
					className: 'button--success'
				},
				{
					label: t('button:cancel'),
					onClick: () => {
						return false
					}
				}
			]
		})
	}

	// Handle stop bulk operation click
	const handleStopBulkOperation = async (operationType: string) => {
		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:stopBulkOperation'),
			buttons: [
				{
					label: t('button:yes'),
					onClick: () => stopBulkOperation(operationType),
					className: 'button--warning'
				},
				{
					label: t('button:cancel'),
					onClick: () => false
				},
				{
					label: t('button:forceStop'),
					onClick: () => stopBulkOperation(operationType, true),
					className: 'button--danger'
				}
			]
		})
	}

	// Handle translation click
	const handleGenerateAllAudio = async () => {
		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:translateAllAudio'),
			buttons: [
				{
					label: t('button:generateAllAudio'),
					onClick: () => generateAllAudio(true),
					className: 'button--warning'
				},
				{
					label: t('button:generateOnlyMissingAudio'),
					onClick: () => generateAllAudio(false),
					className: 'button--success'
				},
				{
					label: t('button:cancel'),
					onClick: () => {
						return false
					}
				}
			]
		})
	}

	const translateAll = async (overwriteAll: boolean) => {
		setIsTranslateAllLoading(true)
		try {
			const response = await apiProtected.post('admin/ai/translate-all', {
				sourceArticleId: article?._id,
				overwriteAll
			})
			if (response?.data?.status === 'success') {
				checkBulkOperationStatus('translation')
			} else {
				setApiError(response.data.message)
			}
		} catch (error) {
			setApiError(apiErrorHandler(error))
		} finally {
			setIsTranslateAllLoading(false)
		}
	}

	const generateAllAudio = async (overwriteAll: boolean) => {
		try {
			const response = await apiProtected.post('admin/ai/audio-all', { sourceArticleId: article?._id, overwriteAll })
			if (response?.data?.status === 'success') {
				checkBulkOperationStatus('audio')
			} else {
				setApiError(response.data.message)
			}
		} catch (error) {
			setApiError(apiErrorHandler(error))
		}
	}

	return (
		<main className="EditArticleTranslations">
			<h1 className="Admin_H1">{t('admin:headerEditArticle')}</h1>

			<HistoryBackLink />

			<InfoBox icon="true" type="info" text={`http://ruya.co/${article?.lang}/article/${article?.slug}`} />

			<AdminArticleNavigation id={id} lang={article?.lang} />
			<ProgressBar
				total={languageStore.allLanguages.filter(lang => lang.active === true).length - 1}
				completed={article?.languageRelations?.length || 0}
			/>

			{apiError && <InfoBox icon="true" type="error" text={apiError || t('error:unexpectedError')} />}

			<FormGroup>
				<FormFieldSet className="EditArticleTranslations_WarningZone" legend={t('admin:bulkHeader')}>
					<div>
						{bulkOperation?.status === 'in-progress' ? (
							<InfoBox icon="true" type="warning" text={t('admin:translationInProgress')}>
								<div className="EditArticleTranslations_WarningZone_Buttons">
									<Button icon="sync" onClick={() => checkBulkOperationStatus('translation')} />
									<Button
										icon="cancel"
										text={t('button:stopBulkTranslation')}
										loading={bulkOperation.stopRequested}
										loadingText={t('button:stopBulkTranslationRequested')}
										color="danger"
										onClick={() => handleStopBulkOperation('translation')}
									/>
								</div>
								<div className="EditPageTranslations_Status">
									<div>
										<strong>{t('admin:section')}:</strong> {bulkOperation?.section}
									</div>
									<div>
										<strong>{t('admin:completed')}:</strong> {bulkOperation?.completed} of {bulkOperation?.total}
									</div>
									<div>
										<strong>{t('admin:translatingLanguage')}:</strong> {bulkOperation?.targetLanguage}
									</div>
									<div>
										<strong>{t('admin:completedChunks')}:</strong> {bulkOperation?.completedChunks} of{' '}
										{bulkOperation?.totalChunks}
									</div>
									<div>
										<strong>{t('admin:failed')}:</strong> {bulkOperation?.failed}
									</div>
									<div>
										{bulkOperation?.errorList.map((error: string, index: number) => (
											<div key={index}>{error}</div>
										))}
									</div>
								</div>
							</InfoBox>
						) : (
							<Button
								icon="translate"
								color="danger"
								text={t('button:translateAll')}
								loading={isTranslateAllLoading}
								disabled={isTranslateAllLoading}
								onClick={handleTranslateAll}
							/>
						)}
					</div>
					<div>
						{bulkOperationAudio?.status === 'in-progress' ? (
							<InfoBox icon="true" type="warning" text={t('admin:translationInProgress')}>
								<div className="EditArticleTranslations_WarningZone_Buttons">
									<Button icon="sync" color="danger" onClick={() => checkBulkOperationStatus('audio')} />
									<Button
										icon="cancel"
										text={t('button:stopBulkAudioGeneration')}
										loading={bulkOperationAudio.stopRequested}
										loadingText={t('button:stopBulkAudioGenerationRequested')}
										color="danger"
										onClick={() => handleStopBulkOperation('audio')}
									/>
								</div>
							</InfoBox>
						) : (
							<Button
								icon="interpreter_mode"
								color="danger"
								onClick={handleGenerateAllAudio}
								text={t('button:generateAllAudio')}
							/>
						)}
					</div>
				</FormFieldSet>
			</FormGroup>

			{article?.lang === languageSettings.defaultLanguage && (
				<table className="EditArticleTranslations_LanguageTable">
					<thead>
						<tr>
							<th />
							<th>{t('admin:columnISO')}</th>
							<th>{t('admin:columnLanguage')}</th>
							<th>{t('admin:columnActions')}</th>
							<th>{t('admin:audio')}</th>
							<th>{t('admin:columnStatus')}</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{languageStore.allLanguages.map((language: ILanguage) => {
							if (language.isoCode !== languageSettings.defaultLanguage && language.active === true)
								return (
									<TranslationItem
										key={language.isoCode}
										language={language}
										article={article}
										setPost={setPost}
										translationButtonsDisable={
											translationButtonsDisable ||
											bulkOperation?.status === 'in-progress' ||
											bulkOperationAudio?.status === 'in-progress'
										}
										setTranslationButtonsDisable={setTranslationButtonsDisable}
									/>
								)
						})}
					</tbody>
				</table>
			)}
		</main>
	)
}

interface TranslationItemProps {
	language: ILanguage
	article: IArticle | null
	setPost: React.Dispatch<React.SetStateAction<IArticle | null>>
	translationButtonsDisable: boolean
	setTranslationButtonsDisable: React.Dispatch<React.SetStateAction<boolean>>
}

const TranslationItem = (props: TranslationItemProps) => {
	// Destruct props
	const { language, article, setPost, translationButtonsDisable, setTranslationButtonsDisable } = props

	// Translation Hook
	const { t } = useTranslation()

	// State
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [translatedArticle, setTranslatedPost] = useState<IArticleLanguageRelation | null>(null)
	const [translationError, setTranslationError] = useState<string | null>(null)

	// Onload check if post translation exists
	useEffect(() => {
		const result = article?.languageRelations?.find((relation: IArticleLanguageRelation) => relation.lang === language.isoCode)
		if (result) {
			setTranslatedPost(result)
		}
	}, [article?.languageRelations])

	// Handle translation click
	const handleTranslation = async () => {
		if (translatedArticle) {
			confirmAlert({
				title: t('dialog:warningTitle'),
				message: t('dialog:translateArticleAgain'),
				buttons: [
					{
						label: t('button:yes'),
						onClick: () => translatePost(),
						className: 'button--warning'
					},
					{
						label: t('button:cancel'),
						onClick: () => {
							return false
						}
					}
				]
			})
		} else {
			translatePost()
		}
	}

	// Handle clone click
	const handleClone = async () => {
		if (translatedArticle) {
			confirmAlert({
				title: t('dialog:warningTitle'),
				message: t('dialog:overwriteArticle'),
				buttons: [
					{
						label: t('button:yes'),
						onClick: () => cloneArticle(),
						className: 'button--warning'
					},
					{
						label: t('button:cancel'),
						onClick: () => {
							return false
						}
					}
				]
			})
		} else {
			cloneArticle()
		}
	}

	// Translate post
	const translatePost = async () => {
		// Disable translation
		setTranslationButtonsDisable(true)
		// Enable translation after 30 seconds
		setTimeout(() => setTranslationButtonsDisable(false), 5000)

		// Reset error
		setTranslationError(null)

		// Set loading
		setIsLoading(true)

		try {
			const response = await apiProtected.post('admin/ai/translate', {
				sourceArticleId: article?._id,
				targetArticleId: translatedArticle?.relatedArticleId || null,
				targetLanguage: language.isoCode
			})

			if (response?.data?.status === 'success') {
				const updatedRelation = response.data.data as IArticle
				// Update parent state
				setPost(prevPost => {
					if (!prevPost) return null

					// Return a completely new object
					return {
						...prevPost,
						languageRelations: updatedRelation.languageRelations
					}
				})
			} else {
				setTranslationError(response.data.message)
			}
		} catch (error) {
			setTranslationError(apiErrorHandler(error))
		}

		setIsLoading(false)
	}

	// Clone post
	const cloneArticle = async () => {
		// Reset error
		setTranslationError(null)

		try {
			const response = await apiProtected.post('admin/article/clone', {
				sourceArticleId: article?._id,
				targetArticleId: translatedArticle?.relatedArticleId || null,
				targetLanguage: language.isoCode
			})

			if (response?.data?.status === 'success') {
				const updatedRelation = response.data.data as IArticle
				// Update parent state
				setPost(prevArticle => {
					if (!prevArticle) return null

					// Return a completely new object
					return {
						...prevArticle,
						languageRelations: updatedRelation.languageRelations
					}
				})
			} else {
				setTranslationError(response.data.message)
			}
		} catch (error) {
			setTranslationError(apiErrorHandler(error))
		}
	}

	// Handle delete click
	const handleDelete = async () => {
		if (translatedArticle) {
			confirmAlert({
				title: t('dialog:warningTitle'),
				message: t('dialog:deleteTranslation'),
				buttons: [
					{
						label: t('button:yes'),
						onClick: () => deleteArticle(),
						className: 'button--danger'
					},
					{
						label: t('button:cancel'),
						onClick: () => {
							return false
						}
					}
				]
			})
		}
	}

	// Delete content
	const deleteArticle = async () => {
		try {
			const response = await apiProtected.delete(`admin/article/${translatedArticle?.relatedArticleId}`)

			if (response?.data?.status === 'success') {
				// Show success message
				toast.success(t('info:translationDeleted'), {
					position: 'bottom-center',
					autoClose: 2000
				})

				setTranslatedPost(null)
			} else {
				// Show error message
				toast.error(response.data.message || t('error:unexpectedError'), {
					position: 'bottom-center',
					autoClose: 2000
				})
			}
		} catch (error) {
			// Show error message
			toast.error(apiErrorHandler(error) || t('error:unexpectedError'), {
				position: 'bottom-center',
				autoClose: 2000
			})
		}
	}

	return (
		<tr
			key={language.isoCode}
			className={`EditArticleTranslations_LanguageTable${
				translatedArticle ? ' EditArticleTranslations_LanguageTable--translated' : ''
			}${translationError ? ' EditArticleTranslations_LanguageTable--error' : ''}`}>
			<td>
				<Tooltip content={language.englishName}>
					<span
						className="EditArticleTranslations_Flag"
						style={{ backgroundImage: `url(${commonSettings.apps.cdn.url}/assets/flags/4x3/${language.flag})` }}
					/>
				</Tooltip>
			</td>
			<td>
				<Tooltip content={language.englishName}>
					<span>{language.isoCode}</span>
				</Tooltip>
			</td>
			<td className="EditArticleTranslations_Language">
				{translatedArticle ? (
					<ReactLink href={`/article/edit/${translatedArticle.relatedArticleId}`}>{language.displayName}</ReactLink>
				) : (
					language.displayName
				)}
			</td>
			<td>
				<div className="EditArticleTranslations_LanguageTable_Actions">
					{isLoading ? (
						<div>{t('admin:translating')}</div>
					) : (
						<>
							<Tooltip content={translatedArticle ? t('admin:overwriteFromOriginal') : t('admin:cloneFromOriginal')}>
								<Button
									size="sm"
									icon="content_copy"
									color={translatedArticle ? 'danger' : 'primary'}
									onClick={handleClone}
									disabled={isLoading}
								/>
							</Tooltip>
							{language.autoTranslate ? (
								<>
									<Button
										text={translatedArticle ? t('admin:translateAgainWith') : t('admin:translateWith')}
										size="sm"
										color={translatedArticle ? 'danger' : 'primary'}
										onClick={handleTranslation}
										disabled={isLoading || translationButtonsDisable}
									/>
								</>
							) : (
								<div>Auto translation disabled.</div>
							)}
						</>
					)}
				</div>
			</td>
			<td className="EditArticleTranslations_LanguageTable_Audio">
				<Tooltip content={t('admin:supportsAudio')}>
					<span>{language.audioGeneration && <MaterialSymbol name="hearing" />}</span>
				</Tooltip>
			</td>
			<td className="EditArticleTranslations_LanguageTable_Status">
				{translationError ? (
					<Tooltip content={translationError}>
						<MaterialSymbol name="error" />
					</Tooltip>
				) : (
					<MaterialSymbol name="translate" className={isLoading ? 'css-flip' : ''} />
				)}
			</td>
			<td className="EditArticleTranslations_LanguageTable_Delete">
				{translatedArticle && <MaterialSymbol name="delete_forever" onClick={handleDelete} />}
			</td>
		</tr>
	)
}

export default Admin(EditArticleTranslations)
