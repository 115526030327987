import './Users.scss'

// Templates
import Admin from '../../templates/admin/Admin'

// Localization
import { useTranslation } from 'react-i18next'

// Charts
import UsersChart from '../../charts/usersChart/UsersChart'
import UserChartByLanguage from '../../charts/userChartByLanguage/UserChartByLanguage'

// Atoms
import H2 from '../../../ruya-shared/shared/ui/atoms/h2/H2'
import H1 from '../../../ruya-shared/shared/ui/atoms/h1/H1'

// Molecules
import Statistics from '../../molecules/statistics/Statistics'

const Users = () => {
	// Translation Hook
	const { t } = useTranslation()

	return (
		<main className="Users">
			<H1 className="Admin_H1">{t('admin:headerUsers')}</H1>

			<div className="Users_Statistics">
				<Statistics />
			</div>

			<div className="Users_Statistics">
				<H2>User Charts</H2>
				<UsersChart />
				<UserChartByLanguage />
			</div>
		</main>
	)
}

export default Admin(Users)
